import React from 'react'
import NavBar from '../../componets/NavBar'
import Footer from '../../componets/Footer'
import UploadVoice from '../../componets/UploadVoice'

export default function upload() {
    return (
        <div className='max-w-7xl flex flex-col justify-between relative mx-auto p-3 min-h-screen'>
            <NavBar theme='light' />
            <div className="flex-grow max-w-[900px] w-full mx-auto">
                <UploadVoice />
            </div>
            <Footer theme='light' />
        </div>
    )
}