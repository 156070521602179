import React from "react";
import logo from "../assets/images/logo1.png";
import LoginForm from "../componets/LoginForm";
import largeLogo from "../assets/images/largeLogo.png";
import { Link } from "react-router-dom";

const Login = () => {
  return (
    <div className="relative flex flex-col lg:flex-row justify-between overflow-x-clip max-w-8xl w-full">
      <Link to="/">
        <img
          src={logo}
          alt=""
          className="h-[60px] lg:h-[118px] mx-auto lg:mx-0"
        />
      </Link>
      <div className="h-screen flex items-center justify-center mr-10">
        <LoginForm />
      </div>

      <div className="lg:flex bg-gradient-to-b from-primary  to-darkBlue hidden lg:w-[50%] justify-center items-center">
        <img
          className="w-[300px] lg:w-[599px] "
          src={largeLogo}
          alt="baseratk logo"
        />
      </div>
    </div>
  );
};

export default Login;
