import React, { useState } from 'react'
import NavBar from '../../componets/NavBar'
import Footer from '../../componets/Footer'

import logo from '../../assets/images/logo1.png'
import { IoMdArrowDropleft } from 'react-icons/io'
import { LiaCheckCircleSolid } from 'react-icons/lia'
import { CgPlayTrackPrevR } from "react-icons/cg";
import { BsCheck2Square } from 'react-icons/bs'
import { Link } from 'react-router-dom'

export default function Course() {
    const [sidebarLevels, setSidebarLevels] = useState(false);
    const [active, setActive] = useState(null)

    const handleClickLevels = () => {
        setSidebarLevels(!sidebarLevels);
    };
    const handleActive = (id) => {
        setActive(active === id ? null : id);
    };

    return (
        <div className='max-w-7xl flex flex-col justify-between relative mx-auto p-3 min-h-screen'>
            <NavBar theme='light' />
            <div className="flex flex-col lg:grid grid-cols-1 lg:grid-cols-[70%_20%]  lg:grid-rows-[auto_1fr] gap-2 md:gap-5 lg:gap-8 lg:justify-end flex-grow text-right  text-primary">
                <div className=" bg-secondary rounded-10 p-2 md:p-5 lg:row-span-2 order-1 lg:order-[0] ">
                    <h3 className='md:text-2xl'>
                        المصفوفات الخاصة وترميز بعض عمليات المصفوفة
                    </h3>
                    <div className='grid grid-cols-2 w-full justify-items-end text-right text-[10px] mt-5 ' >
                        <div className='flex  gap-1 items-center'>
                            <p>
                                يتبقى 59 ثانية من زمن الفيديوهات
                            </p>
                            <CgPlayTrackPrevR />
                        </div>
                        <div className='flex gap-1 items-center'>
                            <p>
                                يتبقى 59 ثانية من زمن الفيديوهات
                            </p>
                            <CgPlayTrackPrevR />
                        </div>
                    </div>
                    <hr className='border    border-primary my-3 w-full left-0' />
                    <div className='flex flex-col gap-2 px-5 py-2'>
                        <p className='text-sm my-2 text-[#4F4E66]'>
                            أهداف التعلم
                        </p>
                        <ul className='list-inside list-disc text-xs px-2 flex flex-col gap-2 text-[#4F4E66]'
                            style={{ direction: 'rtl' }}>
                            <li>
                                افهم ما هي المصفوفة وكيف تتوافق مع التحويل.
                            </li>
                            <li>
                                شرح وحساب معكوس ومحددات المصفوفات
                            </li>
                            <li>
                                حدد واشرح كيفية العثور على المعكوس حسابيًا وما الخطأ الذي يحدث.
                            </li>
                        </ul>

                        <ul className="flex flex-col gap3 mt-5 md:mt-9">
                            {titles.map((item) =>
                                <li
                                    key={item.id}
                                    className='flex flex-col gap-2 items-end transition-all  '>
                                    <button
                                        onClick={() => handleActive(item.id)}
                                        className='flex gap-2 items-center justify-end w-full'>
                                        <p className='text-sm md:text-base'>{item.title}</p >
                                        <IoMdArrowDropleft
                                            alt="arrow"
                                            className={` size-5 transition-transform duration-50 ${active == item.id && '-rotate-90'}`}
                                            onClick={() => handleActive(item.id)}
                                        />
                                    </button>
                                    {item.completed &&
                                        <span className='text-xs bg-lightGreen2 flex items-center gap-1 rounded-10 px-3 py-2 mr-6 my-1 w-fit'>
                                            مكتمل
                                            <BsCheck2Square />
                                        </span>
                                    }

                                    <div
                                        className={`relative overflow-hidden transition-all w-full ease-linear duration-150 p-3 ${active === item.id ? ' opacity-100 py-2' : 'h-0 opacity-0 py-0'}`}
                                    >
                                        <hr className='border  border-primary my-3 w-full left-0 absolute top-0' />
                                        <ul className='mx-3 my-5'>
                                            <li className='my-5 flex gap-2 justify-end text-[10px]'>
                                                المستوى الاول
                                                <span
                                                    className={` rounded-full h-4 w-4 grid place-items-center relative bg-lightGreen `} s>
                                                    <LiaCheckCircleSolid size={22} className='absolute text-black' />
                                                </span>
                                            </li>
                                            <li className='my-5 flex gap-2 justify-end text-[10px]'>
                                                المستوى الثاني
                                                <span
                                                    className={` rounded-full h-4 w-4 grid place-items-center relative bg-lightGreen `} s>
                                                    <LiaCheckCircleSolid size={22} className='absolute text-black' />
                                                </span>
                                            </li>
                                            <li className='my-5 flex gap-2 justify-end text-[10px]'>
                                                المستوى الثالث
                                                <span
                                                    className={` rounded-full h-4 w-4 grid place-items-center relative bg-lightGray `} s>
                                                    {/* <LiaCheckCircleSolid ckCircleSolid size={22} className='absolute text-black' /> */}
                                                </span>
                                            </li>
                                        </ul>
                                        {!item.completed &&
                                            <Link
                                                to={'contents'}
                                                className='text-xs md:text-xls   mr-[100%] text-white  bg-primary hover:bg-hoverPrimary rounded-10 px-5 md:px-8 py-3  w-fit'>
                                                الاستئناف
                                            </Link>
                                        }
                                    </div>
                                </li>
                            )}
                        </ul>

                    </div>
                </div>
                <div className="bg-secondary rounded-10 px-2 py-5 md:pt-5 md:pb-10">
                    <div className="flex gap-2 items-center justify-end ">
                        <h3>
                            تاسيس المحاماة
                        </h3>
                        <img src={logo} alt="logo" className='max-h-10 text-red-400 font-extralight text-sm' />
                    </div>
                    <p className='text-lightGray text-xs'>
                        تهدف الدورة للتعرف على المهارات الأساسية اللازمة لطلاب وطالبات القانون وتاسيسهم بشكل مثالي
                    </p>
                </div>

                <div className="flex gap-5 flex-col bg-secondary rounded-10 px-2 py-5 md:pt-5">
                    <div >

                        <button
                            onClick={handleClickLevels}
                            className='flex gap-2 items-center justify-end w-full'>
                            <h3 className='text-sm'>مواد الدورة التدريبية</h3>
                            <IoMdArrowDropleft
                                alt="arrow"
                                className={` size-5 transition-transform duration-50 ${sidebarLevels && '-rotate-90'}`}
                                onClick={handleClickLevels}
                            />
                        </button>
                        {sidebarLevels &&
                            <ul className='mx-3 my-5'>
                                <li className='my-5 flex gap-2 justify-end text-[10px] '>
                                    المستوى الاول
                                    <span
                                        className={` rounded-full h-4 w-4 grid place-items-center relative bg-lightGreen `}>
                                        <LiaCheckCircleSolid size={22} className='absolute text-black' />
                                    </span>
                                </li>
                                <li className='my-5 flex gap-2 justify-end text-[10px]'>
                                    المستوى الثاني
                                    <span
                                        className={` rounded-full h-4 w-4 grid place-items-center relative bg-lightGreen `}>
                                        <LiaCheckCircleSolid size={22} className='absolute text-black' />
                                    </span>
                                </li>
                                <li className='my-5 flex gap-2 justify-end text-[10px]'>
                                    المستوى الثالث
                                    <span
                                        className={` rounded-full h-4 w-4 grid place-items-center relative bg-lightGray `}>
                                        {/* <LiaCheckCircleSolid ckCircleSolid size={22} className='absolute text-black' /> */}
                                    </span>
                                </li>
                            </ul>}

                    </div>
                    <p className='mr-7 text-sm'>
                        الدرجات
                    </p>
                    <p className='mr-7 text-sm'>
                        الملاحظات
                    </p>
                </div>
            </div>
            <Footer theme='light' />
        </div >
    )
}

// temp data
const titles = [
    { id: 1, title: 'الموضوع 1', completed: true },
    { id: 2, title: 'الموضوع 2', completed: true },
    { id: 3, title: 'الموضوع 3', completed: false },
];
