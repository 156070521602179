import React from 'react'
import { GrCaretNext, GrCaretPrevious } from 'react-icons/gr'

export default function Navigation({ next, prev }) {
    return (
        <div className='flex gap-16 text-darkBlue justify-end'>
            <button className='flex gap-1 cursor-pointer'
                onClick={next}>
                <GrCaretPrevious className='text-darkBlue ' />
                <p>التالي</p>
            </button>
            <button className='flex gap-1 cursor-pointer text-darkBlue '
                onClick={prev}>
                <p>السابق</p>
                <GrCaretNext className='text-darkBlue' />
            </button>
        </div>
    )
}
