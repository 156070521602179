import React, { useEffect } from 'react';
import NavBar from '../componets/NavBar';
import Footer from '../componets/Footer';
import about from '../../src/assets/images/about.jpg';

export default function About() {
    useEffect(() => {
        document.getElementById('root').style.background = '#f5f0ea ';

        return () => {
            document.getElementById('root').style.background = ''; // Reset to default or another color if needed
        };
    }, []);

    return (
        <div className='w-full flex-col justify-between relative mx-auto min-h-screen'>
            <div className='max-w-7xl mx-auto'>
                <NavBar theme='light' />
            </div>
            <img
                src={about}
                className='w-screen h-auto object-cover'
                alt="About"
            />
        </div>
    );
}
