import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { BsFillPhoneFill } from "react-icons/bs";
import { IoMdMail } from "react-icons/io";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { Link } from "react-router-dom";
import { BiCertification } from "react-icons/bi";
import { GoLaw } from "react-icons/go";
import { useAuth } from "../contexts/authContext";
import { RiLockPasswordLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const RegisterForm = () => {
  const [error, setError] = useState(null);
  const [registerFormInputs, setRegisterFormInputs] = useState({
    name: "",
    phone: "",
    email: "",
    role: "",
  });
  const navigate = useNavigate()

  const { registerUser, user } = useAuth();
  useEffect(() => {
    if (user) {
      navigate("/");
    }
    console.log(user);
  });

  const [pageNumber, setPageNumber] = useState(1);

  function handleNextClick() {
    if (registerFormInputs.role) {
      setError('')

      setPageNumber(2);
    } else {
      setError('يرجى تحديد دور')
    }
  }

  function handleBackClick() {
    setPageNumber(1);
    setRegisterFormInputs({
      name: "",
      phone: "",
      email: "",
      role: "",
    });
  }

  function handleInputChange(event) {
    const { name, value } = event.target;
    setRegisterFormInputs((prevState) => ({
      ...prevState,
      [name]: 'trainee',
    }));
    console.log(registerFormInputs);
  }

  return (
    <>
      <div className="relative flex flex-col w-full lg:min-w-[447px] justify-center text-right p-4">
        {/* <form onSubmit={""}> */}
        {pageNumber === 1 && (
          <div className="flex flex-col gap-5 lg:gap-[21px]">
            <h2 className="font-bold text-2xl lg:text-[28px] text-primary mb-6 ">
              {" "}
              : إنشاء حساب
            </h2>
            <div className="flex flex-col gap-3 justify-center lg:gap-10">
              <div className="flex flex-col gap-2 items-end">
                <label className="flex items-center gap-2">
                  <span className="ml-2 text-primary">متدرب</span>
                  <input
                    type="radio"
                    name="role"
                    value="trainee"
                    checked={registerFormInputs.role === "trainee"}
                    onChange={handleInputChange}
                    className="form-radio h-5 w-5 text-[#868AA5]"
                  />
                </label>
                {/* <label className="inline-flex items-center gap-2">
                  <span className="ml-2 text-primary">محامي</span>
                  <input
                    type="radio"
                    name="role"
                    value="lawyer"
                    checked={registerFormInputs.role === "lawyer"}
                    onChange={handleInputChange}
                    className="form-radio h-5 w-5 text-[#868AA5]"
                  />
                </label> */}
              </div>
              {error && <p className="text-right text-darkRed">
                {error}
              </p>}
              <button
                type="button"
                onClick={handleNextClick}
                className="flex justify-center items-center shadow-xl border-2 w-full lg:w-[176px] h-12 lg:h-[60px] hover:bg-hoverPrimary/95  hover:text -[#24283E] bg-primary rounded-xl text-white text-xl lg:text-2xl"
              >
                <MdArrowBackIos />
                <p className="pl-3 text-xl lg:text-2xl font-semibold">التالي</p>
              </button>
            </div>
          </div>
        )}
        {pageNumber === 2 && (
          <>
            {/* <div className="justify-center items-center"> */}
            <button
              type="button"
              onClick={handleBackClick}
              className="ml-auto flex justify-center items-center p-2 shadow-xl border-2 w-full lg:w-fit lg:p-2 h-12 lg:h-fit hover:bg-white hover:text-[#24283E] bg-primary rounded-xl text-white text-xl lg:text-2xl"
            >
              <MdArrowForwardIos />
            </button>
            {/* </div> */}

            {registerFormInputs.role === "trainee" ? (
              <TraineeForm
                registerFormInputs={registerFormInputs}
                setRegisterFormInputs={setRegisterFormInputs}
                register={registerUser}
              />
            ) : (
              <LawyerForm
                registerFormInputs={registerFormInputs}
                setRegisterFormInputs={setRegisterFormInputs}
                register={registerUser}
              />
            )}
          </>
        )}
        {/* </form> */}
      </div>
    </>
  );
};

export default RegisterForm;

const TraineeForm = ({ registerFormInputs, register }) => {
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordCheckMsg, setPasswordCheckMsg] = useState("");
  const [traineeFromData, setTraineeFormData] = useState({
    ...registerFormInputs,
  });
  const Navigate = useNavigate();

  function CheckPasswordChange(e) {
    e.preventDefault();
    setConfirmPassword(e.target.value);
  }

  function handleInputChange(event) {
    const { name, value } = event.target;
    setTraineeFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(registerFormInputs);
  }

  async function handleRegisterClick(e) {
    e.preventDefault();
    // Handle registration logic for lawyer
    if (
      validateTraineeForm(traineeFromData, confirmPassword, setPasswordCheckMsg)
    ) {
      console.log(traineeFromData);
      const response = await register(traineeFromData);
      console.log(response);
      // if(response.message === 'user already exists'){
      //   setPasswordCheckMsg('المستخدم موجود بالفعل')
      // } else{
      //    alert("Trainee registered!");
      //    Navigate("/login") // to be change later
      // }
    }
  }

  return (
    <>
      <div className=" flex flex-col gap-8 w-full self-center lg:w-[447px] lg:h-[575px] text-right p-4">
        <div className="flex flex-col gap-2 lg:gap-[21px]">
          <h2 className="font-bold text-2xl lg:text-[28px] text-primary">
            {" "}
            إنشاء حساب متدرب
          </h2>
          <p className="text-[#303468] text-sm">
            الرجاء ادخال المعلومات الشخصية
          </p>
        </div>
        <div className="rounded-10 h-12 lg:h-16 bg-input flex flex-row-reverse items-center w-full lg:w-[443px]">
          <div className="w-6 h-6 grid place-items-center mr-2 lg:mr-5 ml-2 lg:ml-3 text-[#868AA5]">
            <FaUser />
          </div>
          <input
            type="text"
            name="name"
            value={traineeFromData.name}
            onChange={handleInputChange}
            placeholder="الاسم"
            className="w-full bg-transparent text-right outline-none placeholder:text-placeholder py-2"
            required
          />
        </div>
        <div className="rounded-10 h-12 lg:h-16 bg-input flex flex-row-reverse items-center w-full lg:w-[443px]">
          <div className="w-6 h-6 grid place-items-center mr-2 lg:mr-5 ml-2 lg:ml-3 text-[#868AA5]">
            <BsFillPhoneFill />
          </div>
          <input
            type="text"
            name="phone"
            value={traineeFromData.phone}
            onChange={handleInputChange}
            placeholder="الهاتف"
            className="w-full bg-transparent text-right outline-none placeholder:text-placeholder py-2"
            required
          />
        </div>
        <div className="rounded-10 h-12 lg:h-16 bg-input flex flex-row-reverse items-center w-full lg:w-[443px]">
          <div className="w-6 h-6 grid place-items-center mr-2 lg:mr-5 ml-2 lg:ml-3 text-[#868AA5]">
            <IoMdMail />
          </div>
          <input
            type="email"
            name="email"
            value={traineeFromData.email}
            onChange={handleInputChange}
            placeholder="الايميل"
            className="w-full bg-transparent text-right outline-none placeholder:text-placeholder py-2"
            required
          />
        </div>
        <div className="rounded-10 h-12 lg:h-16 bg-input flex flex-row-reverse items-center w-full lg:w-[443px]">
          <div className="w-6 h-6 grid place-items-center mr-2 lg:mr-5 ml-2 lg:ml-3 text-[#868AA5]">
            <RiLockPasswordLine />
          </div>
          <input
            type="password"
            name="password"
            value={traineeFromData.password}
            onChange={handleInputChange}
            placeholder="كلمة السر"
            className="w-full bg-transparent text-right outline-none placeholder:text-placeholder py-2"
            required
          />
        </div>
        <div className="rounded-10 h-12 lg:h-16 bg-input flex flex-row-reverse items-center w-full lg:w-[443px]">
          <div className="w-6 h-6 grid place-items-center mr-2 lg:mr-5 ml-2 lg:ml-3 text-[#868AA5]">
            <RiLockPasswordLine />
          </div>
          <input
            type="password"
            name="confirmPassword"
            value={confirmPassword}
            onChange={CheckPasswordChange}
            placeholder="تأكيد كلمة السر"
            className="w-full bg-transparent text-right outline-none placeholder:text-placeholder py-2"
            required
          />
        </div>
        {passwordCheckMsg && (
          <p className="text-red-500 text-sm">{passwordCheckMsg}</p>
        )}

        <button
          type="button"
          onClick={handleRegisterClick}
          className="flex justify-center items-center shadow-xl  py-2 border-2 w-full lg:w-[176px] h-12 lg:h-[60px] hover:bg-white hover:text-[#24283E] bg-primary rounded-xl text-white text-xl lg:text-2xl"
        >
          <p className="pl-3 text-xl lg:text-2xl font-semibold">تسجيل</p>
        </button>

        <hr />
        <div className="flex justify-between w-full">
          <Link to={"/login"}>
            <a
              className="font-semibold text-base text-[#24283E] hover:text-pink"
              href="/"
            >
              ادخل الى حسابك
            </a>
          </Link>
          <p className="font-medium text-base">لديك حساب؟</p>
        </div>
      </div>
    </>
  );
};

const LawyerForm = ({ registerFormInputs, register }) => {
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordCheckMsg, setPasswordCheckMsg] = useState("");

  const [laywerFromData, setLaywerFormData] = useState({
    ...registerFormInputs,
    licenseNumber: "",
    field: "",
  });

  function handleInputChange(event) {
    const { name, value } = event.target;
    setLaywerFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(laywerFromData);
  }

  const Navigate = useNavigate();

  function CheckPasswordChange(e) {
    e.preventDefault();
    setConfirmPassword(e.target.value);
  }

  async function handleRegisterClick(e) {
    e.preventDefault();
    // Handle registration logic for lawyer
    if (
      validateLaywerForm(laywerFromData, confirmPassword, setPasswordCheckMsg)
    ) {
      const response = await register(laywerFromData);
      console.log(response);
      // if(response.message === 'user already exists'){
      //   setPasswordCheckMsg('المستخدم موجود بالفعل')
      // } else{
      //   Navigate("/login") // to be change later
      //    alert("Laywer registered!");
      // }
    }
  }

  return (
    <>
      <form onSubmit={() => handleRegisterClick}>
        <div className="relative flex flex-col gap-5 w-full lg:w-[447px] text-right p-4">
          <div className="flex flex-col gap-2 lg:gap-[21px]">
            <h2 className="font-bold text-2xl lg:text-[28px] text-primary">
              {" "}
              إنشاء حساب محامي
            </h2>
            <p className="text-[#303468] text-sm">
              الرجاء ادخال المعلومات الشخصية
            </p>
          </div>
          <div className="rounded-10 h-12 lg:h-12 bg-input flex flex-row-reverse items-center w-full lg:w-[443px]">
            <div className="w-6 h-6 grid place-items-center mr-2 lg:mr-5 ml-2 lg:ml-3 text-[#868AA5]">
              <FaUser />
            </div>
            <input
              type="text"
              name="name"
              value={laywerFromData.name}
              onChange={handleInputChange}
              placeholder="الاسم"
              className="w-full bg-transparent text-right outline-none placeholder:text-placeholder py-2"
              required
            />
          </div>
          <div className="rounded-10 h-12 lg:h-12 bg-input flex flex-row-reverse items-center w-full lg:w-[443px]">
            <div className="w-6 h-6 grid place-items-center mr-2 lg:mr-5 ml-2 lg:ml-3 text-[#868AA5]">
              <BsFillPhoneFill />
            </div>
            <input
              type="text"
              name="phone"
              value={laywerFromData.phone}
              onChange={handleInputChange}
              placeholder="الهاتف"
              className="w-full bg-transparent text-right outline-none placeholder:text-placeholder py-2"
              required
            />
          </div>
          <div className="rounded-10 h-12 lg:h-12 bg-input flex flex-row-reverse items-center w-full lg:w-[443px]">
            <div className="w-6 h-6 grid place-items-center mr-2 lg:mr-5 ml-2 lg:ml-3 text-[#868AA5]">
              <IoMdMail />
            </div>
            <input
              type="email"
              name="email"
              value={laywerFromData.email}
              onChange={handleInputChange}
              placeholder="الايميل"
              className="w-full bg-transparent text-right outline-none placeholder:text-placeholder py-2"
              required
            />
          </div>
          <div className="rounded-10 h-12 lg:h-12 bg-input flex flex-row-reverse items-center w-full lg:w-[443px]">
            <div className="w-6 h-6 grid place-items-center mr-2 lg:mr-5 ml-2 lg:ml-3 text-[#868AA5]">
              <BiCertification />
            </div>
            <input
              type="text"
              name="licenseNumber"
              value={laywerFromData.licenseNumber}
              onChange={handleInputChange}
              placeholder="رقم رخصة المحاماة"
              className="w-full bg-transparent text-right outline-none placeholder:text-placeholder py-2"
              required
            />
          </div>
          <div className="rounded-10 h-12 lg:h-12 bg-input flex flex-row-reverse items-center w-full lg:w-[443px]">
            <div className="w-6 h-6 grid place-items-center mr-2 lg:mr-5 ml-2 lg:ml-3 text-[#868AA5]">
              <GoLaw />
            </div>
            <input
              type="text"
              name="field"
              value={laywerFromData.field}
              onChange={handleInputChange}
              placeholder="التخصص القانوني"
              className="w-full bg-transparent text-right outline-none placeholder:text-placeholder py-2"
              required
            />
          </div>
          <div className="rounded-10 h-12 lg:h-12 bg-input flex flex-row-reverse items-center w-full lg:w-[443px]">
            <div className="w-6 h-6 grid place-items-center mr-2 lg:mr-5 ml-2 lg:ml-3 text-[#868AA5]">
              <RiLockPasswordLine />
            </div>
            <input
              type="password"
              name="password"
              value={laywerFromData.password}
              onChange={handleInputChange}
              placeholder="كلمة السر"
              className="w-full bg-transparent text-right outline-none placeholder:text-placeholder py-2  "
              required
            />
          </div>
          <div className="rounded-10 h-12 lg:h-12 bg-input flex flex-row-reverse items-center w-full lg:w-[443px]">
            <div className="w-6 h-6 grid place-items-center mr-2 lg:mr-5 ml-2 lg:ml-3 text-[#868AA5]">
              <RiLockPasswordLine />
            </div>
            <input
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={CheckPasswordChange}
              placeholder="تأكيد كلمة السر"
              className="w-full bg-transparent text-right outline-none placeholder:text-placeholder py-2  "
              required
            />
          </div>
          {passwordCheckMsg && (
            <p className="text-red-500 text-sm">{passwordCheckMsg}</p>
          )}
          <button
            type="button"
            onClick={handleRegisterClick}
            className="flex justify-center py-2 items-center shadow-xl border-2 w-full lg:w-[176px]  lg:h-[60px] hover:bg-white hover:text-[#24283E] bg-primary rounded-xl text-white text-xl lg:text-2xl"
          >
            <p className="pl-3 text-xl lg:text-2xl font-semibold ">تسجيل</p>
          </button>

          <hr />
          <div className="flex justify-between w-full">
            <Link to={"/login"}>
              <a
                className="font-semibold text-base text-[#24283E] hover:text-pink"
                href="/"
              >
                ادخل الى حسابك
              </a>
            </Link>
            <p className="font-medium text-base">لديك حساب؟</p>
          </div>
        </div>
      </form>
    </>
  );
};

function validateLaywerForm(
  laywerFromData,
  confirmPassword,
  setPasswordCheckMsg
) {
  if (laywerFromData.name?.length < 1) {
    setPasswordCheckMsg("ادخل الاسم");
    return false;
  }
  if (laywerFromData.phone?.length < 1 && laywerFromData.phone?.length === 10) {
    setPasswordCheckMsg(" ادخل الهاتف , ويجب ان يكون من 10 ارقام");
    return false;
  }
  if (laywerFromData.email?.length < 1) {
    setPasswordCheckMsg("ادخل الايميل");
    return false;
  }
  if (!/\S+@\S+\.\S+/.test(laywerFromData.email)) {
    setPasswordCheckMsg("الرجاء ادخال ايميل صحيح");
    return false;
  }
  if (
    laywerFromData.licenseNumber === "" &&
    laywerFromData.licenseNumber?.length < 1
  ) {
    setPasswordCheckMsg("ادخل رقم رخصة المحاماة");
    return false;
  }
  if (laywerFromData.field === "" && laywerFromData.field?.length < 1) {
    setPasswordCheckMsg("ادخل التخصص القانوني");
    return false;
  }
  if (
    laywerFromData.password?.length < 8 ||
    laywerFromData.password?.length > 256
  ) {
    setPasswordCheckMsg("كلمة المرور يجب أن تكون بين 8 و 256 حرفًا");
    return false;
  }
  if (confirmPassword?.length < 1) {
    setPasswordCheckMsg("ادخل تأكيد كلمة السر");
    return false;
  }
  if (laywerFromData.password !== confirmPassword) {
    setPasswordCheckMsg("كلمات المرور غير متطابقة");
    return false;
  }
  return true;
}
function validateTraineeForm(
  traineeFromData,
  confirmPassword,
  setPasswordCheckMsg
) {
  if (traineeFromData.name?.length < 1) {
    setPasswordCheckMsg("ادخل الاسم");
    return false;
  }
  if (traineeFromData.phone?.length < 1) {
    setPasswordCheckMsg("ادخل الهاتف");
    return false;
  }
  if (traineeFromData.email?.length < 1) {
    setPasswordCheckMsg("ادخل الايميل");
    return false;
  }
  if (!/\S+@\S+\.\S+/.test(traineeFromData.email)) {
    setPasswordCheckMsg("الرجاء ادخال ايميل صحيح");
    return false;
  }

  if (
    traineeFromData.password?.length < 8 ||
    traineeFromData.password?.length > 256
  ) {
    setPasswordCheckMsg("كلمة المرور يجب أن تكون بين 8 و 256 حرفًا");
    return false;
  }
  if (confirmPassword?.length < 1) {
    setPasswordCheckMsg("ادخل تأكيد كلمة السر");
    return false;
  }
  if (traineeFromData.password !== confirmPassword) {
    setPasswordCheckMsg("كلمات المرور غير متطابقة");
    return false;
  }
  return true;
}
