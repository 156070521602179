import React from 'react';

export default function ContentList({ data }) {

    data = {
        title: 'تحديد المصفوفات الخاصة',
        content: [
            {
                title: "فقرات عامة",
                text: [
                    "لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. نولا فاسيليسي. دونك فيل سابين ليغولا. فاسيليس أك بيبندوم لاكوس. موربي سيد فرينغيلا أورسي. نولام ديكتوم ليبرو فيتاي أوغي بيللينتيسك، نيك أككومسان دوي فيستيبولوم. إنتيجر سيت أميت دوي إن دوي ألايكويت ديكتوم.",
                    "لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. نولا فاسيليسي. دونك فيل سابين ليغولا. فاسيليس أك بيبندوم لاكوس. موربي سيد فرينغيلا أورسي. نولام ديكتوم ليبرو فيتاي أوغي بيللينتيسك، نيك أككومسان دوي فيستيبولوم. إنتيجر سيت أميت دوي إن دوي ألايكويت ديكتوم.",
                ]
            },
            {
                title: "وصف العمل",
                text: [
                    "لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. نولا فاسيليسي. دونك فيل سابين ليغولا. فاسيليس أك بيبندوم لاكوس. موربي سيد فرينغيلا أورسي. نولام ديكتوم ليبرو فيتاي أوغي بيللينتيسك، نيك أككومسان دوي فيستيبولوم. إنتيجر سيت أميت دوي إن دوي ألايكويت ديكتوم.",
                    "لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. نولا فاسيليسي. دونك فيل سابين ليغولا. فاسيليس أك بيبندوم لاكوس. موربي سيد فرينغيلا أورسي. نولام ديكتوم ليبرو فيتاي أوغي بيللينتيسك، نيك أككوم  سان دوي فيستيبولوم. إنتيجر سيت أميت دوي إن دوي ألايكويت ديكتوم.",
                ]
            },
            {
                title: "توثيق فني",
                text: [
                    "لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. نولا فاسيليسي. دونك فيل سابين ليغولا. فاسيليس أك بيبندوم لاكوس. موربي سيد فرينغيلا أورسي. نولام ديكتوم ليبرو فيتاي أوغي بيللينتيسك، نيك أككومسان دوي فيستيبولوم. إنتيجر سيت أميت دوي إن دوي ألايكويت ديكتوم.",
                    "لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. نولا فاسيليسي. دونك فيل سابين ليغولا. فاسيليس أك بيبندوم لاكوس. موربي سيد فرينغيلا أورسي. نولام ديكتوم ليبرو فيتاي أوغي بيللينتيسك، نيك أككومسان دوي فيستيبولوم. إنتيجر سيت أميت دوي إن دوي ألايكويت ديكتوم.",
                ]
            }
        ]
    };
    return (
        <div className='flex flex-col gap-5 md:gap-10 items-center text-right' style={{ direction: 'rtl' }} >
            <h2 className='w-full  text-2xl md:text-4xl text-black'>{data.title}</h2>
            <ul className='font-medium text-base text-darkGray  '>
                {data.content.map((title, index) => (
                    <li key={index}>
                        <p className='font-bold text-lg md:text-2xl'>{title.title}</p>
                        <ol className='list-decimal mt-1 mr-5'>
                            {title.text.map((paragraph, index2) => (
                                <li key={index2}>
                                    <p>{paragraph}</p>
                                </li>
                            ))}
                        </ol>
                    </li>
                ))}
            </ul>
            <button className=' md:text-3xl w-full max-w-96 bg-primary h-10 md:h-14 text-white rounded-10'>تم قراءة النص</button>
        </div>
    )
}