import React, { useEffect } from 'react'
import Work from '../../componets/lawyer/Work';
import Summary from '../../componets/lawyer/summary';
import { useState } from 'react';
import NavBar from '../../componets/NavBar';
import Footer from '../../componets/Footer';
import WelcomeSection from '../../componets/WelcomeSection';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeft, Star } from 'lucide-react';
import axios from 'axios';
import { new_submissions } from '../../services/api';
import Swal from 'sweetalert2';

function WelcomeLawyer() {
    const [activeTab, setActiveTab] = useState('home');
    const [show, _] = useState('home');

    // Static mock user data for preview
    const user = {
        name: "احمد علي",
        about: "محامي ذو خبرة في مجال القانون المدني والتجاري."
    };

    useEffect(() => {
        const isFirstVisit = localStorage.getItem("firstVisit");

        if (!isFirstVisit) {
            Swal.fire({
                title: 'الشروط والأحكام',
                html: `
          <div style="
            font-weight: bold;
            text-align: right;
            direction: rtl;
            font-family: Arial, sans-serif;
            padding: 20px;
          ">
            <p>الشروط والأحكام بمثابة التزام قانوني، لذلك نأمل قراءة الشروط بعناية والموافقة عليها حتى يتمكن المتدرب من الاستفادة التامة من خدمة التدريب.</p>
            <br />
            <p>وصولك أو تسجيلك أو استمرارك في استخدام الخدمات أو الوصول إليها، فأنت توافق على ما يلي:</p>
            <ul style="
              list-style-type: disc;
              padding-right: 20px;
            ">
              <li>يجب الحفاظ على سرية المحتوى وعدم تداوله.</li>
              <li>يجب على العميل أن يتقيد بتعليمات وإرشادات الشركة فيما يتعلق بالتدريب.</li>
              <li>تقدم منصة الشركة عدد كبير من المستندات والمعلومات القانونية، ومع ذلك يكون المستخدم ملزماً ببذل العناية اللازمة في التأكد من صحة المعلومات.</li>
              <li>يعد مخالفاً استخدام أكثر من شخص اشتراكاً واحداً.</li>
              <li>لا يمكن إلغاء أي طلب اشتراك تم سداد رسومه.</li>
              <li>تخضع هذه الشروط لقوانين المملكة العربية السعودية.</li>
            </ul>
            <br />
            <label style="
              display: flex;
              align-items: center;
              font-family: Arial, sans-serif;
               color: #3D5C70;
               font-weight: bold;
            ">
              <input type="checkbox" id="agreeCheckbox" style="margin-left: 10px;">
              أوافق على الشروط والأحكام
            </label>
          </div>
        `,
                confirmButtonText: 'موافق',
                confirmButtonColor: '#3085d6',
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => {
                    const checkbox = Swal.getPopup().querySelector('#agreeCheckbox');
                    if (!checkbox.checked) {
                        Swal.showValidationMessage('يجب عليك قبول الشروط والأحكام');
                        return false;
                    }
                    return true;
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    localStorage.setItem("firstVisit", "true");
                }
            });
        }
    }, [show]);

    function handleOnClickHome() {
        setActiveTab('home')
    }
    function handleOnClickCourt() {
        setActiveTab('court')
    }

    return (
        <div className='max-w-7xl flex flex-col justify-between mx-auto p-3 min-h-screen'>
            <NavBar theme='light' />
            <div className='flex-grow w-full max-w-[950px] mx-auto'>
                {/* <WelcomeSection user={user} /> */}
                <div className='flex-grow w-full max-w-[950px] mx-auto'>
                    <div className='flex flex-row gap-8 my-10 lg:mx-10 mx-3 justify-end '>
                        <button
                            onClick={handleOnClickCourt}
                            className={`text-darkBlue  md:text-xl border-darkBlue pb-1 ${activeTab === 'court' ? 'border-b-2   cursor-auto' : 'pointer text-darkBlue/[0.6]'}`}>المحكمة الإفتراضية</button>
                        <button
                            onClick={handleOnClickHome}
                            className={`text-darkBlue  md:text-xl border-darkBlue pb-1 ${activeTab === 'home' ? 'border-b-2 cursor-auto' : 'pointer text-darkBlue/[0.6]'}`}>الصفحة الرئيسية</button>
                    </div>
                    {activeTab === 'court' ? <Tasks /> :
                        <Bio user={user} />

                    }
                </div>
            </div>
            <Footer theme='light' />
        </div>
    )
}

const Bio = ({ user }) => {
    return (
        <div className="w-full my-5 rounded-10 bg-darkBlue py-5 md:py-10 px-10 flex flex-col text-right">
            <p className='text-white text-right  md:text-3xl my-5' >ملخصي</p>
            <div className="flex text-darkBlue text-[16px] sm:text-[20px] flex-col gap-2 bg-white p-3 rounded-10 font-bold">
                <div>
                    انا المحامي:
                    {` ${user.name} `}
                </div>
                {
                    user.about ?
                        <>
                            <br />
                            <p className='w-full p-2 text-wrap text-right' style={{ direction: 'rtl', whiteSpace: 'pre-wrap' }}>
                                {user.about}
                            </p>

                        </> :
                        <>
                            <p> لا يوجد لديك ملخص</p>
                            {/* <Link to="" className='bg-darkBlue text-white px-5 py-2 rounded-lg flex gap-2 items-center w-fit'> <ArrowLeft size={15} /> عمل ملخص</Link> */}
                        </>

                }
            </div>
            <p className='text-white text-right md:text-2xl my-5' >تقييمي</p>

            <div className="bg-white flex p-2 rounded-lg gap-1 sm:gap-2 justify-between w-fit flex-row-reverse ml-auto">
                {Array(5).fill().map((_, i) =>
                    i < 0 ? <Star key={i} className='text-[#f1c40f] fill-[#f1c40f]' /> : <Star key={i} className='text-[#bdbcbc] fill-[#bdbcbc]' />
                )}
            </div>
        </div >
    )
}
const Tasks = () => {
    const [tasks, setTasks] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [showEx, setEx] = useState('home');

    const navigate = useNavigate();

    useEffect(() => {
        const loadData = async () => {
            try {
                const data = await new_submissions();
                setTasks(data);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            } finally {
                setIsLoading(false);
            }
        }
        loadData();
    }, []);

    const formatDate = (isoDateString) => {
        const date = new Date(isoDateString);

        if (isNaN(date.getTime())) {
            console.error('Invalid date');
            return '...';
        }

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}/${month}/${day}`;
    };

    const handleSelect = (item) => {
        navigate(`/lawyer/grade/${item.$id}`, { state: { item } });
    };

    return (
        <div className="w-full my-5 rounded-10 bg-darkBlue py-5 md:py-10 px-10 flex flex-col gap-2 text-right relative ">
            <p className='text-white text-right  md:text-3xl my-5' >الأعمال المطلوبه للتقييم </p>

            {isLoading ? (
                <div className="animate-pulse space-y-4 flex items-end flex-col bg-white rounded-10 p-3">
                    <div className="h-4 bg-gray-300 rounded w-3/4"></div>
                    <div className="h-4 bg-gray-300 rounded w-5/6"></div>
                    <div className="h-4 bg-gray-300 rounded w-2/3"></div>
                </div>
            ) :
                (showEx ? (
                    <div className='bg-white w-full rounded-10 p-3 text-darkBlue md:text-xl flex flex-col gap-2' >
                        <p className='font-bold'> المتدرب: <span className='font-semibold'>فهد الغامدي</span></p>
                        <p className='font-bold'>  القضية: <span className='font-semibold'>قضية تجارية</span></p>
                        <p className='font-bold'> تاريخ الإرسال: <span className='font-semibold'>2024/11/01</span></p>

                        <button
                            disabled={true}
                            className='bg-darkBlue text-white rounded-10 w-fit px-4 py-3 mr-auto cursor-pointer'>
                            تقييم
                        </button>
                    </div>
                ) : (
                    <div className='bg-white rounded-10 p-3 text-darkBlue md:text-xl'>
                        <p>لا توجد طلبات مرفوعة حالياً.</p>
                    </div>
                ))}
            <button onClick={() => setEx(!showEx)} className="bg-black text-white p-3 m-4 w-fit absolute -top-20">show/hide example</button>

        </div>
    )
}

export default WelcomeLawyer;
