import React from 'react'
import { Link } from 'react-router-dom';
export default function Quiz({ data }) {

  return (
    <div className='flex flex-col gap-5 md:gap-10 items-end text-right text-black  my-5'>
      <p>الاختبار</p>
      <h2 className='w-full  text-2xl md:text-4xl '>{data.title}</h2>
      <div className='w-full flex flex-col-reverse md:flex-row gap-10  justify-between flex-wrap'>
        <div className='flex flex-col gap-2 justify-center items-end md:items-center'>
          <Link
            className={`${data.grade < 80 && data.grade !== -1 ? 'bg-lightPink text-darkRed border-2 border-darkPink  ' : 'bg-primary text-white'} text-center text-3xl p-2 md:py-5 w-56 rounded-10`}
          >
            عرض التعليقات
          </Link>
          <p>إننا نحتفظ بأعلى نتائجك  </p>
        </div>
        <div>
          <p>تاريخ التسليم <span className='text-darkBlue'>{data.date}</span></p>
          <p>المحاولات <span className='text-primary'>{data.tries}</span></p>
        </div>
      </div>
      <hr className='w-full border-lightGray' />

      <div className='flex justify-end sm:justify-between flex-wrap-reverse min-w-full gap-4  '>
        <Link to='/welcomeTrainee/course/:id/quiz/:quizId'
          className={`${(data.grade !== -1) ? 'bg-darkBlue' : 'bg-primary'} text-white text-center text-3xl p-2 md:py-5 w-56 rounded-10`}>
          {(data.grade !== -1) ? ' إعادة المحاولة' : 'بدء الاختبار'}
        </Link>
        <div className="flex flex-col items-end justify-start gap-2">
          {(data.grade !== -1) && <div className='flex justify-between md:w-44  text-2xl' >
            <p className={`${Number(data.grade) < 80 && 'text-darkRed'}`}>%{data.grade}</p>
            <p>درجتك</p>
          </div>}
          <p className='text-darkGray'>لاجتياز الاختبار يجب الحصول على 80% أو أعلى</p>
        </div>
      </div >
      <div>
        <p className='md:text-3xl'    >
          تم تجاوز عدد المرات المتاحة للمحاولة!
        </p>
        <p className='text-sm md:text-xl'     >
          يرجى المحاولة بعد 7 ساعات و 6 دقائق
        </p>
      </div>

    </div >
  )
}
