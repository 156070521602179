import React, { useState } from 'react'
import { LiaCheckCircleSolid } from "react-icons/lia";
import { IoIosMenu, IoMdClose } from "react-icons/io";

export default function CourseProgress({ titles, onTopicChange }) {
    const [state, setState] = useState({
        activeTopic: null,
        selectedSubtopic: null,
        isOpen: false
    });

    // subtitles hide/show 
    const handleToggle = (id) => {
        setState((prev) => ({
            ...prev,
            activeTopic: prev.activeTopic === id ? null : id,
        }));
    };

    // small screen hide/show 
    const toggleMenu = () => {
        setState((prevState) => ({
            ...prevState,
            isOpen: !(state.isOpen)
        }));
    };

    // change active topic function 
    const handleSubtopicClick = (id) => {
        onTopicChange(id)
        setState((prevState) => ({
            ...prevState,
            selectedSubtopic: id
        }));
        toggleMenu()
    };

    return (
        <>  {state.isOpen && <div
            onClick={toggleMenu}
            className='bg-darkGray bg-opacity-50 absolute w-screen h-screen -right-2 top -0 z-10 md:hidden'> </div>}

            <div className={`${state.isOpen ? 'bg-white shadow-menu h-full px-4' : 'bg-none'} md:px-0 md:shadow-none absolute md:relative md:mt-9 top-3 right-0 md:top-0 md:min-w-48 md:max-w-48 md:min-h-full -translate-y-3 translate-x-2 md:translate-x-0 md:translate-y-0 border-primary  shadow-primary transition-all duration-300 z-20 `}>
                <button onClick={toggleMenu} className='flex items-center justify-center ml-auto'>
                    {state.isOpen ?
                        <IoMdClose size={35} className={`md:hidden`} />
                        : <IoIosMenu size={35} className={`md:hidden`} />}
                </button>
                <div className={` ${state.isOpen ? 'inline' : 'hidden'} md:inline mt-5 z-10`}>
                    <ul>
                        {titles.map((topic) => (
                            <li className='mt-2 cursor-pointer' key={topic.id}>
                                <button className={`font-bold text-sm text-right ${state.activeTopic === topic.id ? 'text-black' : 'text-darkGray'}`} onClick={() => handleToggle(topic.id)}>
                                    {topic.title}
                                </button>
                                {state.activeTopic === topic.id && (
                                    <ul className='text-sm my-3'>
                                        {topic.subTitles.map((subtopic) => (
                                            <li className='flex justify-end cursor-pointer' key={subtopic.id} onClick={() => handleSubtopicClick(subtopic.id)}>
                                                <div>
                                                    <button className='text-sm font-bold text-right'>
                                                        {subtopic.title}
                                                        <p className='text-[10px] font-normal'>
                                                            {subtopic.type}
                                                        </p>
                                                    </button>

                                                </div>
                                                <span
                                                    className={`mx-3 rounded-full h-4 w-4 grid place-items-center relative
                                                ${state.selectedSubtopic === subtopic.id || subtopic.completed
                                                            ? 'bg-lightGreen'
                                                            : 'bg-lightGray'
                                                        }`}
                                                >
                                                    {subtopic.completed && <LiaCheckCircleSolid size={22} className='absolute' />}

                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>

    )
}
