import React, { useState, useEffect, useRef } from 'react';
import { TiStarFullOutline } from "react-icons/ti";
import logo from "../assets/images/logo1.png";
import { RiCalendarScheduleLine } from "react-icons/ri";
import { FaUserGraduate } from "react-icons/fa";
import bgBanner from '../assets/images/Baseratk.webp'

const BuyNowBanner = () => {
  const [showFixedButton, setShowFixedButton] = useState(false);
  const buttonRef = useRef(null);

  const handleScroll = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setShowFixedButton(rect.bottom < 0);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className="relative w-full h-fit  gap-10 p-10 rounded-lg " style={{ backgroundImage: `url(${bgBanner})`, objectFit: 'fill', backgroundPosition: '-180px -260px' }}>
        <div className="flex w-full justify-end align-middle text-center items-center">
          <img src={logo} alt="" className="h-[55px] w-[55px]" />

        </div>
        <div className="flex w-full justify-end align-middle text-center items-center mb-10">
          <p className="text-white font-bold text-2xl">اساسيات المحاماة</p>
        </div>
        <div className="flex w-full justify-end align-middle text-center items-center mb-10">
          <p className="text-white text-1xl text-right font-light md:w-[50%] lg:w-[50%]">
            {" "}
            لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. فيستيبولوم
            فيهيكولا أورنا سيت أميت ماغنا أوللامكوربر، أك تينسيدونت نيسل فيهيكولا.
            بروين لاوريت ديام إن إيرات فيفيرا، آت سوليكيتودين أوغي فيفيرا.
          </p>
        </div>
        <div className="flex w-full justify-end align-middle text-center items-center mt-[30px]">
          <p className="text-white text-1xl text-right font-light md:w-fit lg:w-fit bg-purple rounded-10 p-3 hover:bg-blue-500">
            150 ر.س
          </p>
        </div>
        <div className="flex w-full justify-end align-middle text-center items-center mt-[30px]">
          <button ref={buttonRef} className="text-black text-1xl text-center font-light md:w-[250px]  lg:w-[300px] bg-white rounded-10 p-4 hover:bg-transparent border border-white hover:text-purple cursor-pointer">
            اشتر الان
          </button>
        </div>

        <div className='relative  z-30 lg:top-40  md:top-30 top-20 justify-around items-center bg-white shadow-md shadow-black/15 w-[70wv] h-[200px] flex flex-col lg:flex-row md:flex-row rounded-lg lg:text-2xl md:text-xl font-bold text-darkBlue'>
          <div className='flex items-center justify-center gap-2'>
            <p> جدول مواعيد مرنة</p>
            <RiCalendarScheduleLine />

          </div>
          <div className='flex items-center justify-center gap-2'>
            <p>  مبتدئ المستوى</p>
            <FaUserGraduate />
          </div>
          <div className='flex items-center justify-center gap-2'>
            <p> 4.9  </p>
            <TiStarFullOutline color="#FCDB65" size={20} />
          </div>
        </div>

        {showFixedButton && (
          <div className="fixed bottom-0 left-0 right-0 w-full flex justify-center items-center  mb-0 z-50 bg-primary hover:bg-gray-300 text-white hover:text-white shadow-2xl shadow-black">
            <button className="text-inherit text-1xl text-center font-light bg-transparent rounded-[10px] p-4  ">
              اشتر الان
            </button>
          </div>
        )}
      </div>

    </>
  );
};

export default BuyNowBanner