import React, { useState } from 'react';
import { useAuth } from '../contexts/authContext'; // Update the import path
import { useNavigate, useNavigation } from 'react-router-dom';
import Swal from 'sweetalert2';

const PasswordRecovery = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate()

    const { recoverPassword } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const recoveryUrl = 'https://baseratk.com/new-password'; // Update with your reset URL
        try {
            await recoverPassword(email);

        } catch (error) {
            setMessage('حدث خطأ أثناء إرسال رسالة الاسترداد. يرجى المحاولة مرة أخرى');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="relative flex flex-col gap-8 w-full lg:w-[447px] justify-center lg:h-[575px] text-right p-4">
                <div className="flex flex-col gap-8 w-full self-center lg:w-[447px] lg:h-[575px] text-right p-4">
                    <div className="flex flex-col gap-2 lg:gap-[21px]">
                        <h2 className="font-bold text-2xl lg:text-[28px] text-[#2F3367]">
                            استعادة كلمة المرور
                        </h2>
                        <p className="text-[#303468] text-sm">
                            الرجاء إدخال بريدك الإلكتروني لتلقي تعليمات استعادة كلمة المرور.
                        </p>
                    </div>

                    <div className="rounded-10 h-12 lg:h-16 bg-input flex flex-row-reverse items-center w-full lg:w-[443px]">
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="أدخل بريدك الإلكتروني"
                            className="w-full bg-transparent text-right outline-none placeholder:text-placeholder"
                            required
                        />
                    </div>

                    {message && (
                        <p className={`text-sm text-right ${message.includes('نجاح') ? 'text-green-500' : 'text-red-500'}`}>
                            {message}
                        </p>
                    )}

                    <button
                        type="submit"
                        className="flex justify-center items-center shadow-xl border-2 w-full p-2 hover:bg-white hover:text-[#24283E] bg-primary rounded-xl text-white text-xl lg:text-2xl"
                    >
                        <p className="pl-3 text-xl lg:text-2xl font-semibold">
                            إرسال بريد الاسترداد
                        </p>
                    </button>
                </div>
            </div>
        </form>
    );
};

export default PasswordRecovery;
