import React, { useState } from 'react'
import NavBar from '../../componets/NavBar'
import Footer from '../../componets/Footer'

import pattern from '../../assets/images/Baseratk-3.webp'
// icons
import { CiWarning } from "react-icons/ci";
import { TbFileStar } from "react-icons/tb";
import { BsFillStarFill } from 'react-icons/bs'

export default function Lawyers() {
  const [state, setState] = useState('list')
  const [selectedLawyerId, setSelectedLawyerId] = useState(null);

  const handleViewChange = (view, lawyerId = null) => {
    if (lawyerId) {
      setSelectedLawyerId(lawyerId);
    }
    setState(view);
  };

  return (
    <div className='max-w-7xl mx-auto min-h-screen flex flex-col justify-between text-right text-primary' >
      <NavBar theme='light' />

      {state === 'list' && (
        <LawyersList onSelectLawyer={(id) => handleViewChange('details', id)} onRequest={(id) => handleViewChange('confirm', id)} />
      )}

      {state === 'details' && (
        <LawyersDetails lawyerId={selectedLawyerId} onRequest={(id) => handleViewChange('confirm', id)} />
      )}

      {state === 'confirm' && (
        <ConfirmLawyer lawyerId={selectedLawyerId} onConfirm={() => handleViewChange('list', selectedLawyerId)} />
      )}

      <Footer theme='light' />
    </div>
  )
}

const LawyerContainer = ({ onSelectLawyer, onRequest }) => {
  const handleClick = () => {
    onRequest('nawaf')
  }

  return (
    <div className='shadow-lg w-full bg-white p-2 md:p-5 rounded-10'>
      <div className='bg-secondary p-2 md:p-5 rounded-10  '>
        <div className='flex gap-3 justify-end'>
          <div>
            <h3 className='text-sm md:text-base'>
              نواف النباتي
            </h3>
            <p className='text-lightGray py-2 text-xs md:text-sm'
              style={{ direction: 'rtl' }}
            >
              تهدف هذه الدورة للتعرف على المهارات الأساسية اللازمة لطلاب وطالبات القانون وتأسيسهم بشكل مثالي يتناسب مع متطلبات سوق العمل <span>
                <button
                  onClick={() => onSelectLawyer('nawaf')}
                  className='text-primary'> قراءة  المزيد  </button>
              </span>
            </p>
          </div>
          <div className='bg-red-400 min-h-[75px] min-w-[64px]'>
          </div>
        </div>
        <div className="flex md:items-end flex-col-reverse md:flex-row gap-2  flex-wrap justify-between">
          <div className='flex flex-grow flex-col md:flex-row'>

            <button
              onClick={handleClick}
              className='rounded-10 p-3 md:p-5 text-white bg-primary hover:bg-hoverPrimary'>
              طلب إشراف
            </button>

            <button
              disabled
              className='rounded-10 cursor-default p-3 md:p-5 text-white bg-slate-500 '>
              تم الإرسال
            </button>

          </div>
          <div className=' py-3 flex flex-row-reverse justify-start gap-3 text-[10px] text-lightGray'>
            <BsFillStarFill className='text-[#FCDB65] ' />
            <p className='text-primary'>4.9</p>
            <p>
              {"( اشرف على 100 متدرب)"}
            </p>
          </div>

        </div>
      </div>
    </div >
  )
}
const LawyersList = ({ onSelectLawyer, onRequest }) => {

  return (
    <div className=' w-full flex flex-col gap-2 justify-start items-end flex-grow my-2'>
      <h2 className='md:text-xl my-10 px-2' >المدربين المتاحين لتقيمي</h2>
      <div className=' px-2 w-full'>
        <div className='flex justify-end gap-2 p-2 rounded-10 border-2 border-darkRed bg-lightPink'>
          <p className='text-black text-xs md:text-base py-2'>
            تنبيه في حال عدم إشراف أي محامي لك قد لا تتمكن من الإنتهاء من الدورة الحالية ؛ إشراف المحامي عليك متطلب من متطلبات النجاح لك. إختر بعناية مشرفك لزيادة مهاراتك!
          </p>
          <CiWarning className='text-darkRed h-9 w-9 md:h-12 md:w-12    ' />
        </div>
      </div>
      <LawyerContainer onSelectLawyer={onSelectLawyer} onRequest={onRequest} />
    </div>
  )
}

const LawyersDetails = ({ lawyerId, onRequest }) => {
  const [rating, setRating] = useState(3);

  // fetch the lawyer data from id :useEffect
  return (
    <div className='px-2  w-full flex flex-col gap-2 justify-start items-end flex-grow my-2'>
      <h2 className='md:text-xl my-6'>
        ملخص المدرب
      </h2>
      <img className='absolute w-screen inline-block translate-y-20 left-0 -z-10' src={pattern} alt="pattern" />
      <div className='p-2 md:p-5  bg-secondary rounded-10 w-full mt-56'>

        <div className="p-2 md:p-5 bg-white rounded-10 w-full relative">

          <div className="bg-white rounded-10 p-3 md:p-5 shadow-md w-full max-w-[950px] h-fit  mx-auto -translate-y-1/2
                        flex flex-col gap-3 justify-between">
            <div className='flex gap-3 justify-end items-center w-full'>
              <div className='flex-grow flex flex-col gap-3 items-start'>
                <p className='text-darkGray text-left '>
                  {" ( قانون جنائي)"}
                </p>
                <button className='flex gap-2 items-center   bg-darkGray p-2 md:py-2 md:px-4 text-white hover:bg-gray-500 lightGray text-[9px] md:text-base'>
                  عرض السيرة الذاتية
                  <TbFileStar />
                </button>
              </div>
              <div className="flex flex-col gap-3">
                <p className='text-sm md:text-base '>
                  نواف النباتي
                </p>
                <div className='flex flex-row-reverse gap-1'>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <BsFillStarFill
                      key={star}
                      className={`text-xl ${star <= rating ? 'text-yellow-500' : 'text-gray-400'}`}
                    />
                  ))}
                </div>
              </div>
              <div className="bg-red-600 h-16 w-16 rounded-full"></div>
            </div>
            <button
              onClick={() => onRequest('nawaf')}
              className='mt-3 md:mt-9 rounded-10 p-3 md:p-5 text-white bg-primary hover:bg-hoverPrimary'>
              طلب إشراف
            </button>
          </div>
          <Details />
        </div>
      </div>
    </div>

  )
}

const Details = () => {
  const [active, setActive] = useState('about');

  const handleClick = (component) => {
    setActive(component);
  };

  return (
    <div className="">
      <nav className='flex justify-center gap-2 md:gap-5 p- md: p- 4 border-b  border-lightGray '>
        <button onClick={() => handleClick('replies')}
          className={`text-lightGray py-2  md:text-lg w-40 ${active == 'replies' && 'border-b-2 border-primary text-primary '}`}>
          الردود
        </button>
        <button onClick={() => handleClick('about')}
          className={`text-lightGray py-2 md:text-lg w-40 ${active == 'about' && 'border-b-2 border-primary text-primary '}`}>
          ملخص المحامي
        </button>
      </nav>
      <main >
        {active === 'about' && <About />}
        {active === 'replies' && <Replies />}
      </main>
    </div>
  )
}
const About = () => {
  return (
    <p className='py-5 md:py-10 text-darkGray text-center text-sm md:text-lg'>
      بسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. نولا فاسيليسي. دونك فيل سابين ليغولا. فاسيليس أك بيبندوم لاكوس. موربي سيد فرينغيلا أورسي. نولام ديكتوم ليبرو فيتاي أوغي بيللينتيسك، نيك أككومسان دوي فيستيبولوم. إنتيجر سيت أميت دوي إن دوي ألايكويت ديكتوم.لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. نولا فاسيليسي. دونك فيل سابين ليغولا. فاسيليس أك بيبندوم لاكوس. موربي سيد فرينغيلا أورسي. نولام ديكتوم ليبرو فيتاي أوغي بيللينتيسك، نيك أككومسان دوي فيستيبولوم. إنتيجر سيت أميت دوي إن دوي ألايكويت ديكتوم.لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. نولا فاسيليسي. دونك فيل سابين ليغولا. فاسيليس أك بيبندوم لاكوس. موربي سيد فرينغيلا أورسي. نولام ديكتوم ليبرو فيتاي أوغي بيللينتيسك، نيك أككومسان دوي فيستيبولوم. إنتيجر سيت أميت دوي إن دوي ألايكويت ديكتوم.لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. نولا فاسيليسي. دونك فيل سابين ليغولا. فاسيليس أك بيبندوم لاكوس. موربي سيد فرينغيلا أورسي. نولام ديكتوم ليبرو فيتاي أوغي بيللينتيسك، نيك أككومسان دوي فيستيبولوم. إنتيجر سيت أميت دوي إن دوي ألايكويت ديكتوم.        </p>
  )
}
const Replies = () => {

  return (
    <div className="flex flex-col py-5 md:py10 gap-2 md:gap-5">
      <Reply />
      <Reply />
      <Reply />
      <Reply />
      <Reply />
      <Reply />
    </div>
  )
}
const Reply = () => {
  const [rating, setRating] = useState(3);

  return (
    <div className="w-full bg-secondary p-5 md:p-10 flex justify-end gap-3">
      <div>
        <div className='flex flex-row-reverse gap-1'>
          {[1, 2, 3, 4, 5].map((star) => (
            <BsFillStarFill
              key={star}
              className={`text-xl ${star <= rating ? 'text-yellow-500' : 'text-gray-400'}`}
            />
          ))}
        </div>
        <p className='text-[10px] md:text-sm'>
          محامي جيد جدا مبدع، تقيماته في محلها وعين الصواب اوصي به رائع في مجال القانون الجنائي ومتألق في مجاله
        </p>
      </div>
      <div className="bg-red-600 h-8 min-w-8 "></div>
    </div>
  )
}
const ConfirmLawyer = ({ lawyerId, onConfirm }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [msg, setMsg] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleClick = () => {
    if (!isChecked) {
      setMsg(true)
      return
    }
    setMsg(false)
    onConfirm()
    // send request useing id function
  }
  return (
    <div className='p-2 md:p-5  lg:p-0 lg:pb-1 lg:pt-5 lg:pr-5 text-black  bg-secondary rounded-10 w-full h-full flex-grow flex'
      style={{ direction: 'rtl' }}
    >
      <div className='px-5 py-14 bg-white relative lg:-left-10 shadow-md rounded-10 w-full flex flex-col justify-between md:text-lg'>
        <h1 className='text-2xl'>شروط المتدرب:</h1>
        <ul
          className='list-decimal list-inside text-xs md:text-sm py-5 flex flex-col gap-5'>
          <li>
            <h2 className='text-lg py-2 inline-block'>
              التسجيل الدقيق:
            </h2>
            <p>
              يتعين على المتدرب تقديم معلومات شخصية دقيقة عند التسجيل في المنصة، والتأكد من تحديثها بانتظام لضمان التواصل الفعّال وتلقي الإشعارات الضرورية.
            </p>
          </li>

          <li>
            <h2 className='text-lg py-2 inline-block'>                                الالتزام بخطة التدريب:
            </h2>
            <p>
              يجب على المتدرب الالتزام بالخطة التدريبية المقررة، بما في ذلك إكمال الدورات والمهام المطلوبة وفقًا للجدول الزمني المحدد.
            </p>
          </li>

          <li>

            <h2 className='text-lg py-2 inline-block'>                                المسؤولية الذاتية:
            </h2>
            <p>
              يتحمل المتدرب المسؤولية الذاتية في متابعة تقدمه، وإكمال المهام والأنشطة التدريبية دون الحاجة إلى إشراف مستمر.    </p>
          </li>

          <li>

            <h2 className='text-lg py-2 inline-block'>
              طلب التوجيه عند الحاجة:
            </h2>
            <p>
              يتعين على المتدرب المبادرة بطلب التوجيه من المشرفين أو استخدام الموارد المتاحة على المنصة في حال مواجهة أي صعوبة في فهم المحتوى أو إتمام المهام.
            </p>
          </li>

          <li>

            <h2 className='text-lg py-2 inline-block'>
              التفاعل مع ملاحظات المشرفين:
            </h2>
            <p>

              يتعين على المتدرب التعامل بجدية مع ملاحظات المشرفين أو نتائج التقييمات، والعمل على تحسين الأداء بناءً عليها لتحقيق أقصى استفادة من التدريب.
            </p>
          </li>
          <li>

            <h2 className='text-lg py-2 inline-block'>
              المحافظة على سرية المعلومات والامتثال لشروط الاستخدام الخاصة بالمنصة:                            </h2>
            <p>
              يُلزم المتدرب بالمحافظة على سرية المعلومات المتعلقة بالتدريب والمحتوى التعليمي والبرمجيات المستخدمة داخل المنصة، وعدم مشاركتها مع أي طرف خارجي دون إذن.
            </p>
          </li>
          <li>

            <h2 className='text-lg py-2 inline-block'>
              الإبلاغ عن المشكلات:
            </h2>
            <p>
              يتعين على المتدرب الإبلاغ فورًا عن أي مشكلات تقنية قد يواجهها أثناء استخدام المنصة لضمان استمرارية التدريب دون انقطاع.
            </p>
          </li>
          <li>

            <h2 className='text-lg py-2 inline-block'>
              الموافقة على استخدام المعلومات:
            </h2>
            <p>
              يوافق المتدرب على أن تقوم المنصة باستخدام البيانات والمعلومات المتعلقة بأدائه وتقدمه التعليمي لأغراض تحسين خدمات المنصة، وإجراء التحليلات اللازمة لتطوير البرامج التعليمية، مع الالتزام بحماية خصوصية هذه البيانات وعدم الإفصاح عنها لأطراف ثالثة دون إذن.                            </p>
          </li>

        </ul>

        <div>
          <div className='flex gap-3 md:items-center px-2 py-4 '>
            <input type="checkbox" name="confirmCheckBox" id="confirmCheckBox"
              checked={isChecked} onChange={handleCheckboxChange}
              className='outline-none border-none bg-red-500 h-5 w-5 md:h-8 md:w-8 accent-primary'
            />
            <label htmlFor='confirmCheckBox' className='text-sm'>
              اقر انني اطلعت على الشروط المذكورة بالاعلى واني اوافق عليها
            </label>

          </div>
          <button
            onClick={handleClick}
            // disabled={!isChecked}
            className='rounded-10 shadow-md bg-secondary py-5 px-14 text-center'>
            موافق
          </button>
          <p className='text-darkRed'>{msg && "يرجى الموافقة على الشروط!"}</p>
        </div>
      </div>
    </div>

  )

}