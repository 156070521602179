import React from "react";

import NavBar from "../componets/NavBar";
import BuyNowBanner from "../componets/BuyNowBanner";
import { MdDownloadDone } from "react-icons/md";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoMdArrowDropleft } from "react-icons/io";
import { PiDotOutlineFill } from "react-icons/pi";
import Footer from '../componets/Footer'
import { useParams } from "react-router-dom"


const CourseDetails = () => {
  const { id } = useParams()

  return (
    <div className="max-w-7xl mx-auto flex flex-col mb-[100px]">
      <NavBar theme="light" />
      <h1 className="text-right lg:text-2xl tefont-black text-darkBlue mx-10 my-10" >
        المحتوى التعليمي &gt; تاسيس المحاماة
      </h1>

      <BuyNowBanner />
      <EmptySpace />
      <CourseContent />
      <SkillsSection />
      <CourseTopics />
      <Footer theme='light'/>
    </div>
  );
};

export default CourseDetails;

export const CourseContent = () => {
  return (
    <div className="relative flex flex-col items-end w-full justify-end px-7 gap-5">
      <p className="font-bold text-lg lg:text-3xl">:ماذا ستتعلم</p>
      <CheckItem />
      <CheckItem />
      <CheckItem />
      <CheckItem />
    </div>
  );
};

const EmptySpace = () => {
  return (
    <div className=" flex items-center justify-center w-full lg:h-[200px] md:h-[150px] h-[100px]"></div>
  );
};

const CheckItem = () => {
  return (
    <div className="flex justify-end gap-5 w-full lg:w-[70%]">
      <p className="text-right text-lg lg:text-2xl">
        {" "}
        لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. نولا
        فاسيليسي. دونك فيل سابين ليغولا. فاسيليس أك بيبندوم لاكوس.
      </p>
      <MdDownloadDone size={30} />
    </div>
  );
};

const SkillsSection = () => {
  return (
    <div className="flex flex-col gap-10 items-end mt-10">
      <p className="font-bold text-1xl lg:text-3xl mr-10">:المهارات المكتسبة</p>
      <div className="flex flex-wrap justify-center gap-10 w-full lg:w-[100%]">
        <SkillItem skill="القرارات" />
        <SkillItem skill="تفسير لنصوص القانونية" />
        <SkillItem skill="كتابة اللوائح" />
        <SkillItem skill="البحث القانوني" />
        <SkillItem skill="لوائح الاعتراض والاتهام" />
        <SkillItem skill="القرارات" />
        <SkillItem skill="القرارات" />
      </div>
    </div>
  );
};

const SkillItem = (props) => {
  return (
    <div className="w-fit h-auto bg-[#49454F]/10 p-5 rounded-lg overflow-auto mx-2 font-bold lg:text-2xl">
      {props.skill}
    </div>
  );
};

const CourseTopics = () => {
  return (
    <div className="flex flex-col items-end w-full justify-end px-7 gap-5 mt-10">
      <p className="font-bold text-lg lg:text-3xl mb-10 ">:محتويات الدورة</p>
      <div className="flex flex-wrap  justify-center gap-10 w-full lg:w-[100%] bg-[#98A0C5]/20 rounded-[10px] py-10 lg:px-0 px-10">
        <TopicItem />
        <TopicItem />
        <TopicItem />
        <TopicItem />
        <TopicItem />
        {/* Map all the TopicItems */}
      </div>
    </div>
  );
};
const TopicItem = () => {
  const [isOpenMain, setIsOpenMain] = React.useState(false);
 

  const toggleOpenMainTopic = () => {
    setIsOpenMain(!isOpenMain);
  };


  return (
    <>
      <div
        className="flex justify-end gap-5 w-full lg:w-[85%] border-b pb-10 border-b-black cursor-pointer"
        onClick={toggleOpenMainTopic}
      >
        <p className="text-right text-lg lg:text-2xl font-bold">
          لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. نولا
          فاسيليسي. دونك فيل سابين ليغولا. فاسيليس أك بيبندوم لاكوس.
        </p>

        {isOpenMain ? (
          <IoMdArrowDropdown size={50} />
        ) : (
          <IoMdArrowDropleft size={50} />
        )}
      </div>

      {isOpenMain && (
        <>
        <div className="flex flex-col items-end text-right gap-5 w-full lg:w-[75%] pb-10">
          <LearningObjectives
            objectives={[
              "افهم ما هي المصفوفة وكيف تتوافق مع التحويل.",
              "شرح وحساب معكوس ومحددات المصفوفات",
            ]}
          />
          
        <SubTopicItem/>
        {/* Map all SubTpoicItems */}
        </div>
        </>
      )}
    </>
  );
};

const SubTopicItem = () => {
  const [isOpenSub, setIsOpenSub] = React.useState(false);
  const toggleOpenSubTopic = () => {
    setIsOpenSub(!isOpenSub);
  };

  return (
    <>
      <div
        className="flex justify-end gap-5 w-full lg:w-[85%] border-b pb-10 mt-10 cursor-pointer"
        onClick={toggleOpenSubTopic}
      >
        <p className="text-right text-md lg:text-lg font-bold">
          لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. نولا
          فاسيليسي. دونك فيل سابين ليغولا. فاسيليس أك بيبندوم لاكوس.
        </p>
        {isOpenSub ? (
          <IoMdArrowDropdown size={50} />
        ) : (
          <IoMdArrowDropleft size={50} />
        )}
      </div>
      {isOpenSub && (
        <>
       <LessonItem/>
       <LessonItem/>
       <LessonItem/>
       <LessonItem/>
       </>
      )}
    </>
  );
};

const LessonItem = () =>{
  return (
    <div className="flex flex-col items-end w-full lg:w-[85%] mr-10">
    <p className="text-[#49454F] font-bold text-lg">
      المحددات والانعكاسات
    </p>
    <p className="text-[#55577A]">فيديو 10 دقائق</p>
  </div>

  )
}

const LearningObjectives = ({ objectives }) => {
  return (
    <>
      <p className="lg:text-2xl">أهداف التعلم</p>
      <ul className="items-end flex flex-col text-right gap-5">
        {objectives?.map((objective, index) => (
          <li key={index} className="flex gap-2">
            {objective} <PiDotOutlineFill />
          </li>
        ))}
      </ul>
    </>
  );
};
