import React, { useEffect, useState } from "react";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/authContext";
import { useNavigate } from "react-router-dom";
export default function ForgetPasswordForm() {

    const [errorMsg, setErrorMsg] = useState("");
    const { recoverPassword, user } = useAuth();
    const navigate = useNavigate();
    const [formData, setFormData] = useState('');

    useEffect(() => {
        if (user) {
            navigate('/')
        }
    });

    const handleChange = (e) => {
        setFormData(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                await recoverPassword(formData);
            } catch (error) {
                setErrorMsg('حدث خطأ أثناء إرسال رسالة الاسترداد. يرجى المحاولة مرة أخرى');
            }
        }
    };
    const handleSubmit1 = async (e) => {
        e.preventDefault();
        const recoveryUrl = 'https://baseratk.com/new-password'; // Update with your reset URL
        try {
            // await recoverPassword(email);

        } catch (error) {
            // setMessage('حدث خطأ أثناء إرسال رسالة الاسترداد. يرجى المحاولة مرة أخرى');
        }
    };

    const validateForm = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            setErrorMsg("البريد الإلكتروني غير صالح");
            return false;
        }
        return true;
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="relative flex flex-col gap-8 w-full lg:w-[447px] justify-center lg:h-[575px] text-right p-4">
                <div className="justify-center items-center">
                    <div className=" flex flex-col gap-8 w-full self-center lg:w-[447px] lg:h-[575px] text-right p-4">
                        <div className="flex flex-col gap-2 lg:gap-[21px]">
                            <h2 className="font-bold text-2xl lg:text-[28px] text-[#2F3367]">
                                إعادة تعيين كلمة المرور
                            </h2>
                            <p className="text-[#303468] text-sm">
                                الرجاء ادخال البريد الإلكتروني لإرسال رابط إعادة تعيين كلمة المرور
                            </p>
                        </div>

                        <div className="rounded-10 h-12 lg:h-16 bg-input flex flex-row-reverse items-center w-full lg:w-[443px]">
                            <div className="w-6 h-6 grid place-items-center mr-2 lg:mr-5 ml-2 lg:ml-3 text-[#868AA5]">
                                <MdOutlineAlternateEmail />
                            </div>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="أيميل"
                                className="w-full bg-transparent text-right outline-none placeholder:text-placeholder"
                                required
                            />
                        </div>

                        {errorMsg && (
                            <p className="text-red-500 text-sm text-right">{errorMsg}</p>
                        )}

                        <button
                            onClick={handleSubmit}
                            type="submit"
                            className="flex justify-center items-center shadow-xl border-2 w-full lg:w-[176px] h-12 lg:h-[60px] hover:bg-white hover:text-[#24283E] bg-primary rounded-xl text-white text-xl lg:text-2xl"
                        >
                            <p className="pl-3 text-xl lg:text-2xl font-semibold">إرسال</p>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )
}
