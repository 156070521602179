import React, { useState, useEffect, useRef } from "react";
import lightLogo from "../assets/images/logo1.png";
import darkLogo from "../assets/images/logo2.png";
import { IoIosMenu } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { SideMenu } from "./SideBar";
import { useAuth } from "../contexts/authContext";
import { FaAngleDown } from "react-icons/fa6";
import avater from "../assets/icons/male.svg";
import { IoMdNotificationsOutline } from "react-icons/io";
import { MdLanguage } from "react-icons/md";
import { new_notifications } from "../services/api";

export const NavBar = ({ theme }) => {

  const textColor = theme === "light" ? "text-[#303468]" : "text-white";
  const IconColor = theme === "light" ? "#303468" : "white";
  const borderColor = theme === "light" ? "border-[#303468]" : "border-[#fff]";
  const logo = theme === "light" ? darkLogo : lightLogo;
  const hoverTextColor =
    theme === "light" ? "hover:text-blue" : "hover:text-pink";
  const hoverBgColor =
    theme === "light" ? "hover:bg-[#f0f0f0]" : "hover:bg-white";

  const { user, logoutUser } = useAuth();
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const notificationRef = useRef(null);
  const userRef = useRef(null);
  const sideMenuRef = useRef(null);
  const [userType, setUserType] = useState(user?.role === 'lawyer' ? '/lawyer' : "/trainee")
  const [tempNotfs, setTempNotfs] = useState(0)
  useEffect(() => {
    if (user) {

      setIsLoggedin(false);
    } else {
      setIsLoggedin(true)
    }

  }, [user]);

  const handleClickOutside = (event) => {
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target)
    ) {
      setShowNotificationModal(false);
    }
    if (userRef.current && !userRef.current.contains(event.target)) {
      setShowUserModal(false);
    }
    if (sideMenuRef.current && !sideMenuRef.current.contains(event.target)) {
      setShowSideMenu(false);
    }
  };

  useEffect(() => {
    const checkGrades = async () => {
      try {
        const res = await new_notifications(user?.$id)
        console.log(res);
        setTempNotfs(res)
      } catch (error) {
        console.error('error fetch data: ', error)
      }
    }
    if (user && user.role == 'trainee')
      checkGrades()

    // document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <nav className="flex justify-between p-5">
        <div className={`flex gap-5 pl-5 ${textColor} font-bold pt-6`}>
          <IoIosMenu
            size={"35px"}
            className={`lg:hidden md:hidden ${textColor}`}
            onClick={() => setShowSideMenu(!showSideMenu)}
          />

          <SideMenu
            show={showSideMenu}
            onClose={() => setShowSideMenu(false)}
          />
          {isLoggedin ? (
            <>
              <Link
                to="/register"
                className={`${borderColor} rounded-[20px] border-[2px] h-[30px] w-fit px-4 justify-center ${hoverTextColor} ${hoverBgColor} cursor-pointer hidden md:block lg:block`}
              >
                <p>انشاء حساب</p>
              </Link>
              <Link
                to="/login"
                className={`${borderColor} rounded-[20px] border-[2px] h-[30px] w-fit px-4 justify-center ${hoverTextColor} ${hoverBgColor} cursor-pointer hidden md:block lg:block`}
              >
                <p>تسجيل دخول</p>
              </Link>
            </>
          ) : (
            <>
              <div className=" justify-center items-center gap-4 hidden md:flex lg:flex">
                <div
                  className="flex cursor-pointer justify-center items-center gap-1 "
                  onClick={() => setShowUserModal(!showUserModal)}
                  ref={sideMenuRef}
                >
                  <FaAngleDown
                    size={"15px"}
                    className="hover:animate-bounce cursor-pointer"
                    color={`${IconColor}`}
                  />
                  <img
                    src={avater}
                    alt=""
                    className="h-[35px] w-[35px] bg-transparent border border-transparent hover:bg-darkBlue rounded-full "
                  />
                </div>
                <div className="border border-white h-5 "></div>
                <div className="relative inline-block">
                  <IoMdNotificationsOutline
                    size={"25px"}
                    className="hover:animate-pulse hover:text-red-500 cursor-pointer"
                    color={`${IconColor}`}
                    onClick={() => setShowNotificationModal(!showNotificationModal)}
                  />
                  {tempNotfs > 0 &&
                    <>
                      <span className="absolute top-0 right-0 inline-flex h-2 w-2 rounded-full bg-red-500 animate-ping"></span>
                      <span className="absolute top-0 right-0 inline-flex h-2 w-2 rounded-full bg-red-500"></span>
                    </>
                  }
                </div>
                <FaAngleDown size={"15px"} color={`${IconColor}`} className="hidden" />
                <p className={`hidden ${textColor}`}>العربية</p>
                <MdLanguage size={"20px"} scolor={`${IconColor}`} className="hidden" />
              </div>
            </>
          )}
        </div>

        <div className="flex justify-between items-center pl-5 gap-10">
          <Link
            to="/about"
            className={`self-center hidden lg:block md:block ${textColor} ${hoverTextColor} cursor-pointer`}
          >
            <p>من نحن</p>
          </Link>
          <Link
            to="/Courses"
            className={`self-center hidden lg:block md:block ${textColor} ${hoverTextColor} cursor-pointer`}
          >
            <p>المحتوى التعليمي</p>
          </Link>
          <Link to={user?.role == 'trainee' || user?.role == 'lawyer' ? `/${user.role}` : '/'}
            className={`self-center hidden lg:block md:block ${textColor} ${hoverTextColor} cursor-pointer`}
          >
            <p>الصفحة الرئيسية</p>
          </Link>
          <Link to={'/'}>
            <img className="w-[80px] object-fill" src={logo} alt="Logo" />
          </Link>
        </div>
      </nav>
      {showNotificationModal && (
        <div ref={notificationRef} className="absolute top-20 left-[200px] bg-gray-50
         shadow-md rounded-lg p-4">
          {tempNotfs > 0 ?
            <div className="border-b-2 w-full flex justify-between items-center gap-2 p-1 my-3">
              <p className="text-sm text-right text-primary"> لقد تلقيت تقييمًا يمكنك رؤيته في صفحة التقيمات</p>
              <IoMdNotificationsOutline className="text-primary" />
            </div> :
            <p className="text-sm text-right text-primary"> لا يوجد اشعارات</p>
          }
        </div>
      )}
      {showUserModal && (
        <div ref={userRef} className="absolute top-20 left-20 bg-white shadow-md rounded-lg p-4">
          <ul className="text-black flex flex-col">
            <Link className="hidden hover:bg-gray-200 p-2 cursor-pointer">ملفي الشخصي</Link>
            <Link to={userType} className="hidden hover:bg-gray-200 p-2 cursor-pointer" onClick={""}>
              تعليمي
            </Link>
            <Link className="hidden hover:bg-gray-200 p-2 cursor-pointer">اعدادات</Link>
            <Link className="hover:bg-gray-200 p-2 cursor-pointer" onClick={logoutUser}>تسجيل الخروج</Link>
          </ul>
        </div>
      )}
    </>
  );
};

export default NavBar;
