import React from "react";
import { NavBar } from "../componets/NavBar";
import Hero from "../componets/Hero";
import Footer from "../componets/Footer";

const Home = () => {
  return (
    <main
      className="relative flex flex-col justify-between max-w-8xl mx-auto w-full
            sm:px-10 px-5 min-h-screen overflow-clip pb-10 "
      style={{ background: 'linear-gradient(to bottom, #062841, #3d5c80 75%,  #ede4d6 )' }}
    >
      <NavBar />
      <Hero />
      <Footer />

    </main>
  );
};

export default Home;
