import React from 'react'
import { Link } from 'react-router-dom'
import { ArrowLeft } from 'lucide-react';

const Work = ({work ,isWork}) => {
  return (
    <div className="flex bg-[#24283e] text-right sm:p-8 p-5 rounded-xl flex-col gap-3">
        <div className='text-white text-[25px] text-right  w-full py-6' >الأعمال المطلوبه للتقييم </div>
        <div className="flex flex-col gap-8">
{isWork ?
work.map((item,index)=>{
    return(
        <div className="flex text-[15px] sm:text-[20px] flex-col gap-2 bg-white p-3 rounded-lg font-bold" key={index}>
            <div> رد المتدرب : {item.name}</div>
            <div>   {item.state}: القضية  </div>
            <div> {item.reply}: الرد  </div>
            <div className="flex flex-row-reverse justify-between items-center gap-7 text-[12px] sm:text-[15px] " >
                <div>تاريخ الارسال : {item.date}</div>
                <div>المتبقي لتقييم المتدرب: {item.timeForRate}</div>
                <Link to="" className='bg-[#24283e] text-white px-5 py-2 rounded-lg flex gap-2 items-center '> <ArrowLeft size={15} /> التفاصيل </Link>
            </div>

        </div>
    )
})
:   <div className="flex text-[15px] sm:text-[20px] flex-col gap-2 bg-white p-3 rounded-lg font-bold" >
<div>لا يوجد اعمال تحتاج الي تقييم</div>
<Link className='flex justify-center items-center flex-row-reverse gap-4 py-2 text-[15px] bg-[#24283e] text-white w-[80%] mx-auto rounded-lg my-10' to="">تصفح المتدربين المتاحين للتقييم <ArrowLeft /></Link>
</div>}
        </div>

    
    </div>
  )
}

export default Work