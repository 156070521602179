import React, { useEffect, useState } from 'react';
import ExploreCourses from "../../componets/ExploreCourses";
import { NavBar } from "../../componets/NavBar";
import WelcomeSection from "../../componets/WelcomeSection";
import Notice from "../../componets/Notice";
import Learing from "../../componets/Learing";
import SkillSection from "../../componets/SkillSection";
import Footer from "../../componets/Footer";
import { useAuth } from '../../contexts/authContext';
import { FaArrowLeft, FaArrowLeftLong } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import court from '../../assets/icons/court.svg'
import Swal from 'sweetalert2';

const WelcomePage = () => {
  const [activeTab, setActiveTab] = useState('home');
  const [isUserSub, setIsUserSub] = useState(false)
  const { user } = useAuth()

  function handleOnClickHome() {
    setActiveTab('home')
  }
  function handleOnClickCourt() {
    setActiveTab('court')
  }

  useEffect(() => {
    const isFirstVisit = localStorage.getItem("firstVisit");

    if (!isFirstVisit) {
      Swal.fire({
        title: 'الشروط والأحكام',
        html: `
          <div style="
            font-weight: bold;
            text-align: right;
            direction: rtl;
            font-family: Arial, sans-serif;
            padding: 20px;
          ">
            <p>الشروط والأحكام بمثابة التزام قانوني، لذلك نأمل قراءة الشروط بعناية والموافقة عليها حتى يتمكن المتدرب من الاستفادة التامة من خدمة التدريب.</p>
            <br />
            <p>وصولك أو تسجيلك أو استمرارك في استخدام الخدمات أو الوصول إليها، فأنت توافق على ما يلي:</p>
            <ul style="
              list-style-type: disc;
              padding-right: 20px;
            ">
              <li>يجب الحفاظ على سرية المحتوى وعدم تداوله.</li>
              <li>يجب على العميل أن يتقيد بتعليمات وإرشادات الشركة فيما يتعلق بالتدريب.</li>
              <li>تقدم منصة الشركة عدد كبير من المستندات والمعلومات القانونية، ومع ذلك يكون المستخدم ملزماً ببذل العناية اللازمة في التأكد من صحة المعلومات.</li>
              <li>يعد مخالفاً استخدام أكثر من شخص اشتراكاً واحداً.</li>
              <li>لا يمكن إلغاء أي طلب اشتراك تم سداد رسومه.</li>
              <li>تخضع هذه الشروط لقوانين المملكة العربية السعودية.</li>
            </ul>
            <br />
            <label style="
              display: flex;
              align-items: center;
              font-family: Arial, sans-serif;
              color: #3D5C70;
               font-weight: bold;
            ">
              <input type="checkbox" id="agreeCheckbox" style="margin-left: 10px;">
              أوافق على الشروط والأحكام
            </label>
          </div>
        `,
        confirmButtonText: 'موافق',
        confirmButtonColor: '#3085d6',
        allowOutsideClick: false,
        allowEscapeKey: false,
        preConfirm: () => {
          const checkbox = Swal.getPopup().querySelector('#agreeCheckbox');
          if (!checkbox.checked) {
            Swal.showValidationMessage('يجب عليك قبول الشروط والأحكام');
            return false;
          }
          return true;
        }
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.setItem("firstVisit", "true");
        }
      });
    }
  }, [user?.id]);

  return (
    <div className=' max-w-7xl min-h-screen mx-auto flex flex-col justify-between py-3'>
      <NavBar theme="light" />
      <div className='flex-grow w-full max-w-[950px] mx-auto'>
        <WelcomeSection user={user} />
        <div className='flex flex-row gap-8 my-10 lg:mx-10 mx-3 justify-end '>
          <button
            onClick={handleOnClickCourt}
            className={`text-darkBlue  md:text-xl border-darkBlue pb-1 ${activeTab === 'court' ? 'border-b-2   cursor-auto' : 'pointer text-darkBlue/[0.6]'}`}>المحكمة الإفتراضية</button>
          <button
            onClick={handleOnClickHome}
            className={`text-darkBlue  md:text-xl border-darkBlue pb-1 ${activeTab === 'home' ? 'border-b-2 cursor-auto' : 'pointer text-darkBlue/[0.6]'}`}>الصفحة الرئيسية</button>
        </div>

        {activeTab === 'court' ? <Court /> :
          <>
            {/* <HomeUnSubscribed /> */}
            <Submissions />
          </>
          // (isUserSub ? <HomeSubscribed /> :
          // )
        }
      </div>
      <Footer theme='light' />
    </div>
  );
};

function HomeUnSubscribed() {
  return (

    <div>
      <ExploreCourses />
      <SkillSection />
    </div>

  )
}
function Submissions() {
  return (

    <div className="mx-2 lg:mx-10 my-5 rounded-10 bg-darkBlue py-5 md:py-10 px-10 flex flex-col">
      <h3 className="text-white text-right  md:text-3xl my-5">التقيمات</h3>
      <Link
        to={'submissions'}
        className="w-full rounded-10 text-darkBlue  bg-white hover:bg-white/95 text-center  py-5 flex gap-2 items-center justify-center">
        <FaArrowLeft />
        <p>عرض</p>
      </Link>
    </div>

  )
}
function HomeSubscribed() {
  return (
    <div>
      <Learing />
      <Notice />
      <SkillSection />
    </div>

  )
}
function Court() {
  return (
    <div className='mx-5 flex flex-col justify-center gap-3'>
      <div className='p-5 md:p-10 w-full rounded-[22px] gap-5 bg-darkBlue flex flex-col justify-center items-end'>
        <div className='w-full md:flex flex-row-reverse justify-between items-center'>
          <h2 className='text-right text-3xl text-white '> المحكمة الافتراضية</h2>
          <Link
            to={'upload'}
            className='hidden bg-secondary md:flex justify-between items-center rounded-10 w-fit gap-5 self-center hover:bg-secondary/90 px-10 py-5'>
            <p className='text-primary text-lg md:text-xl'> رفع الجلسة</p>
            <img src={court} alt="court icon" className='size-8' />
          </Link>
        </div>
        <p className="text-white text-xs lg:text-lg text-right self-end"
          style={{ direction: 'rtl' }}>
          تجربة افتراضية تفاعلية تحاكي أسلوب المحكمة التقليدية، يتقمص أحد المتدربين فيها دور محامي المدعي، بينما يتولى الآخر دور محامي المدعى عليه. تبدأ الجلسة بعد اختيار قضية افتراضية محددة تتضمن تفاصيل القضية والمستندات ذات العلاقة، ويُطلب من الطرفين استغلال كل الأسانيد والأدلة المتاحة لكسب القضية. بعد انتهاء الجلسة سيتم رفعها لمحامٍ مختص للتقييم الدقيق.
        </p>
        <a
          href={process.env.REACT_APP_DOWNLOAD_COURT}
          target='_blank'
          className='w-full py-5 bg-white justify-center items-center self-center rounded-10 hover:bg-white/90'>
          <p className='text-inherit text-lg md:text-xl w-full text-center mx-auto'> تنزيل</p>
        </a>
        <Link
          to={'upload'}
          className='md:hidden bg-secondary flex justify-center items-center rounded-10 w-full gap-5 self-center hover:bg-secondary/90 px-10 py-5'>
          <p className='text-primary text-lg md:text-xl'> رفع الجلسة</p>
          <img src={court} alt="court icon" className='size-8' />
        </Link>

        {/* <div className='flex gap-5  p-10 lg:pr-20 justify-center items-center '>
          <p className='text-white text-right  md:text-3xl text-lg'>تجربة المحكمة الافتراضية</p>
        </div> */}
        {/* <Link
          to={'upload'}
          className='w-[90%] bg-white  grid place-items-center rounded-full self-center mb-10 hover:bg-white/90 py-2'>
          <p className='text-inherit text-lg md:text-xl'> رفع الجلسة</p>
        </Link> */}
      </div>

      {/* <div className='h-fit lg:py-5 w-full rounded-[22px] bg-darkBlue mt-10 flex flex-col justify-center items-end'>
        <div className='flex gap-0  p-10 lg:pr-20 justify-center items-center '>
          <p className='text-white font-bold text-right  lg:text-4xl text-lg'>:جلساتي   </p>

        </div>
        <div className="h-fit w-[90%] rounded-[22px] p-10 bg-white justify-center items-center self-center flex flex-col mb-10">
          <p className='text-center text-darkBlue lg:text-3xl font-bold '>لديك 3 جلسات  متاحة من المحكمة الإفتراضية الافتراضية</p>
          <Link to={"/Subscribe"} className='lg:w-[50%] md:w-[50%] w-full h-[20%] bg-darkBlue border-[2px] border-darkBlue text-white items-center self-center rounded-[25px] my-10 p-2 flex justify-evenly hover:bg-hoverPrimary   '>
            <FaArrowLeftLong size={'20px'} color='inherit' />
            <p className='text-inherit  text-lg lg:text-2xl'> الاشتراك بجلسات اضافية</p>

          </Link>

        </div>

      </div> */}

    </div>
  )
}

export default WelcomePage
