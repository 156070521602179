import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';
import logo from "../assets/images/logo1.png";
import avater from "../assets/icons/male.svg";

export const SideMenu = ({ show, onClose, theme }) => {
  const { user, logoutUser } = useAuth();
  const menuRef = useRef(null);

  const [isLoggedin, setIsLoggedin] = useState(false);
  const [userType, setUserType] = useState(user?.role === 'lawyer' ? '/lawyer' : "/trainee")

  useEffect(() => {
    if (user) {
      setIsLoggedin(true);
    } else {
      setIsLoggedin(false);
    }
  }, [user]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div
      className={`fixed inset-0 bg-gray-800 bg-opacity-50 z-50 ${show ? "block" : "hidden"}`}
      onClick={onClose}
    >
      <div ref={menuRef} className="absolute top-0 left-0 w-64 bg-black h-full shadow-lg text-[#3d5c70]" onClick={(e) => e.stopPropagation()}>
        <div className="flex flex-col p-4">
          <button onClick={onClose} className="self-end mb-4">اغلاق</button>
          <img className="w-[80px] h-[80px] mb-4" src={logo} alt="Logo" />
          <Link to={user?.role == 'trainee' || user?.role == 'lawyer' ? `/${user.role}` : '/'}
            className="p-2 hover:bg-gray-200">الصفحة الرئيسية</Link>
          <Link to="/Courses" className="p-2 hover:bg-gray-200">المحتوى التعليمي</Link>
          <Link to="/about" className="p-2 hover:bg-gray-200">من نحن</Link>
          {isLoggedin ? (
            <>
              <div className="flex flex-col cursor-pointer justify-center items-center gap-1 mb-4 mt-10 border-t-[2px] pt-5 border-[#3d5c70]  text-[#3d5c70] ">
                <img src={avater} alt="" className="h-[35px] w-[35px] bg-transparent border-transparent border-[1px] rounded-full border-[#3d5c70] hover:bg-darkBlue" />
                <span>{user.name}</span>
              </div>
              <Link className="hover:bg-gray-200 text-darkRed p-2 cursor-pointer" onClick={logoutUser}>تسجيل الخروج</Link>
              <Link to="/profile" className="hidden  p-2 hover:bg-gray-200">ملفي الشخصي</Link>
              <Link to="/profile" className="hidden  p-2 hover:bg-gray-200">ملفي الشخصي</Link>
              <Link to={userType} className="hidden  p-2 hover:bg-gray-200">تعليمي</Link>
              <Link to="/settings" className="hidden  p-2 hover:bg-gray-200">اعدادات الحساب</Link>
              {/* <button onClick={() => { logout(); onClose(); }} className="p-2 hover:bg-gray-200">Logout</button> */}
            </>
          ) : (
            <>
              <Link to="/login" className="p-2 hover:bg-gray-200">تسجيل دخول</Link>
              <Link to="/register" className="p-2 hover:bg-gray-200">انشاء حساب</Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
