import React from 'react'

export default function Video({ data }) {
    data = {
        "link": "https://example.com/video.mp4",
        "text": "لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. فيستيبولوم فيهيكولا أورنا سيت أميت ماغنا أوللامكوربر، أك تينسيدونت نيسل فيهيكولا. بروين لاوريت ديام إن إيرات فيفيرا، آت سوليكيتودين أوغي فيفيرا. سيد ألايكويت أورسي نون دوئي سكيليسكو، فيل تينسيدونت ماوريس هندريريت."
    }
    return (
        <div className='flex flex-col gap-5 md:gap-10 items-end text-right'  >
            <video className='w-full border-2 my-5' autoPlay controls>
                <source src={data.link} ></source>
            </video>
            <div>
                <h2 className='w-full  text-2xl md:text-4xl text-black'>ملخص</h2>
                <p className='mt-1'>
                    {data.text}
                </p>
            </div>
        </div>
    )
}
