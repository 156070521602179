import React from "react";
import { NavBar } from "../../componets/NavBar";
import { IoIosArrowForward } from "react-icons/io";
import Star from "../../assets/icons/star.svg";
import { IoIosArrowRoundBack } from "react-icons/io";
import Footer from "../../componets/Footer";

const SkillsPage = () => {
  // data comes from props
  // Example
  const LawField = {
    title: "كتابة اللوائح والمذكرات القانونية",
    subTitle: "قم بتحديث مهاراتك الحالية",
    skills: [
      {
        name: "المذكرات الاعتراضية",
        level: "مبتدئ",
        currentGrade: 44,
      },
      {
        name: "المذكرات القضائية",
        level: "متوسط",
        currentGrade: 60,
      },
      {
        name: "المذكرات القانونية",
        level: "متقدم",
        currentGrade: 80,
      },
    ],
  };

  return (
    <div className="flex flex-col max-w-7xl self-center mx-auto gap-10 mb-12">
      <div className="w-full h-fit">
        <NavBar theme="light" />
      </div>

      <div className="flex w-full justify-end h-fit">
        <BackButton />
      </div>

      <div className="flex flex-col w-full h-fit items-center gap-10">
        <h1 className="text-2xl lg:text-5xl font-bold text-primary">
          {LawField.title}
        </h1>
        <p className="text-sm text-gray-500">{LawField.subTitle}</p>
      </div>

      <div className="flex flex-col w-full h-fit items-center gap-10">
        <SkillContainer skills={LawField.skills} />
      </div>
      <Footer />
    </div>
  );
};

export default SkillsPage;

const SkillBox = ({ skill }) => {
  return (
    <div className="flex flex-col lg:min-w-[340px] lg:h-[250px] w-[300px] h-[250px] items-end justify-start bg-[#F0F0F0] rounded-[20px] p-10">
      <div className="flex gap-2 justify-between">
        <p className="text-1xl font-bold text-primary ">{skill.name}</p>
        <img src={Star} alt="Star" className="w-[25px]" />
      </div>

      <div className="flex w-full justify-end gap-4 mt-4">
        <div className="w-fit h-[25px] bg-[#EAE3E3] px-3 rounded-md">{skill.level}</div>
        <p className="text-primary">الدرجة الحالية: {skill.currentGrade}</p>
      </div>

      <div className="flex w-full justify-end gap-4 mt-4">
        <div className="progress-bar" style={{ "--progress-value": `${skill.currentGrade}%` }}></div>
      </div>

      <div className="flex w-full justify-end mt-5">
        <button className="bg-primary text-white px-4 py-2 rounded-[20px] flex justify-center items-center gap-2">
          <IoIosArrowRoundBack size={"2rem"} /> لمزيد من التفاصيل
        </button>
      </div>
    </div>
  );
};

const BackButton = () => {
  return (
    <button
      className="flex w-fit h-[50px] items-center rounded-[8px] bg-primary px-4 mr-5"
      onClick={() => console.log('Back button clicked')}
    >
      <p className="text-white">العودة</p>
      <IoIosArrowForward size="20px" className="text-white" />
    </button>
  );
};

const SkillContainer = ({ skills }) => {
  return (
    <div className="flex flex-col w-full h-fit items-center justify-center gap-5 bg-primary rounded-[59px] p-[40px]">
      <div className="flex lg:justify-end justify-center w-full my-5">
        <h2 className="text-2xl text-right lg:text-3xl text-white">
          تفاصيل مهاراتي في (كتابة اللوائح والمذكرات)
        </h2>
      </div>
      <div className="grid lg:grid-cols-3 grid-cols-1 gap-10">
        {skills.map((skill, index) => (
          <SkillBox key={index} skill={skill} />
        ))}
      </div>
    </div>
  );
};