import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://127.0.0.1:8000", // Your FastAPI server URL or a default value
});

export const user_submissions = async (userId) => {
  try {
    const response = await api.get('/get_full_user_submissions', {
      params: { user_id: userId },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching submissions:', error);
    throw error; 
  }
};
export const new_submissions = async () => {
  try {
    const response = await api.get('/get_new_submissions');
    return response.data.data;
  } catch (error) {
    console.error('Error fetching submissions:', error);
    throw error;
  }
};

export const grade_submission = async (body) => {
  
  try {
    const response = await api.post('/grade_submission', body);
    return response.data.data;
  } catch (error) {
    console.error('Error grade submission:', error);
    throw error;
  }
};

export const new_notifications = async (userId) => {
  try {
    const response = await api.get('/get_full_user_submissions', {
      params: { user_id: userId },
    });
     const submissions = response.data.data;
    const gradedCount = submissions.filter(submission => submission.submission_status === 'graded').length;

    return gradedCount
  } catch (error) {
    console.error('Error fetching :', error);
    throw error;
  }
};
export const upload_submission = async (body) => {
  try {
    const response = await api.post('/upload_submission' , body, {});
    return response.data;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};

// Api Requests
// export const registerUser = async (formData) => {
//   try {
//     console.log(formData)
//     const response = await api.post("/register", formData);
//     console.log(response.data)
//     return response.data;
//   } catch (error) {
//     console.error("Error registering user:", error);
//     return {"message": "user already exists"}
   
//   }
// };

// export const loginUser = async (formData) => {
//   try {
  
//     const response = await api.post("/login", formData);
//     localStorage.setItem("token", response.data.access_token);
//     localStorage.setItem('userId' , response.data.userId)
//     console.log(response.data)
//     return response.data;
//   } catch (error) {
//     console.error("Error logging in user:", error);
//     throw error;
//   }
// };

// export const logoutUser = async () => {
//   try {
//     const token = localStorage.getItem("token");
//     if (!token) throw new Error("No token found");
//     const response = await api.post("/logout", {}, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     localStorage.removeItem("token");
//     localStorage.removeItem("userId");
//     return response.data;
//   } catch (error) {
//     console.error("Error logging out user:", error);
//     return { message: "Failed to logout user" };
//   }
// };

// export const getProfile = async () => {
//   try {
//     const token = localStorage.getItem("token");
//     const userId = localStorage.getItem("userId");
//     if (!token) throw new Error("No token found");
//     console.log('foo')
//     const response = await api.get("/profile", {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//       params: {
//         userId: userId
//       }

//     });
//     console.log(response.data)
//     return response.data;
//   } catch (error) {
//     console.error("Error fetching profile:", error);
//     throw error;
//   }
// };
