import React, { useState } from 'react';
import { NavBar } from "../../componets/NavBar";
import CourseProgress from "../../componets/CourseProgress";
import Navigation from "../../componets/Navigation";
import Reading from "../../componets/Reading.jsx";
import Video from "../../componets/Video.jsx";
import Writing from "../../componets/UploadVoice.jsx";
import Quiz from "../../componets/Quiz.jsx";
import Footer from "../../componets/Footer.jsx";

export default function Contents({ CourseId }) {

    const findObjectById = (targetId) => {
        for (const topic of data.topics) {
            for (const subTitle of topic.subTitles) {
                if (subTitle.id === targetId) {
                    return subTitle;
                }
            }
        }
        return null;
    };

    const [activeTopic, setActiveTopic] = useState(() => findObjectById(1.1));

    const handleTopicChange = (targetId) => {
        const subTitle = findObjectById(targetId);
        if (subTitle) {
            setActiveTopic(subTitle);
        }
    };

    const sidebarData = data.topics.map(item => ({
        id: item.id,
        title: item.title,
        subTitles: item.subTitles.map(subItem => ({
            id: subItem.id,
            title: subItem.title,
            completed: subItem.completed,
            type: subItem.typeText
        }))
    }));

    return (
        <div className='max-w-7xl flex flex-col justify-between relative mx-auto p-3 min-h-screen'>
            <NavBar theme='light' />
            <div className="flex flex-col-reverse items-end lg:justify-between lg:flex-row lg:items-center">
                <div className='ml-4 mr-auto my-2'>
                    <Navigation />
                </div>
                <h2 className='text-primary  text-right md:text-2xl  my-2 md:my-5'>
                    المحتوى التعليمي &gt; تاسيس المحاماة &gt; المستوى الثاني
                </h2>
            </div>
            <div className='flex md:gap-5 flex-col md:flex-row relative  h-full '>
                <div className='flex-grow md:px-16 h-full lg:border-r-2 border-black pt-5 justify-start  mt-9'>

                    {activeTopic.type === 'video' && <Video />}
                    {activeTopic.type === 'reading' && <Reading data={activeTopic} />}
                    {activeTopic.type === 'quiz' && <Quiz data={activeTopic.content} />}
                    {activeTopic.type === 'writing' && <Writing />}

                </div>
                <CourseProgress titles={sidebarData} onTopicChange={handleTopicChange} />
            </div>
            <Footer />
        </div>
    );

}

// ! temporary variable 
let data = {
    "topics": [
        {
            "id": 1,
            "title": "المصفوفات الخاصة وترميز بعض عمليات المصفوفة",
            "subTitles": [
                {
                    "id": 1.1,
                    "title": "عنوان فرعي",
                    "type": "video",
                    "typeText": "فيديو 10 دقائق",
                    "completed": true,
                    "content": {
                        "link": "https://example.com/video.mp4",
                        "text": "لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. فيستيبولوم فيهيكولا أورنا سيت أميت ماغنا أوللامكوربر، أك تينسيدونت نيسل فيهيكولا. بروين لاوريت ديام إن إيرات فيفيرا، آت سوليكيتودين أوغي فيفيرا. سيد ألايكويت أورسي نون دوئي سكيليسكو، فيل تينسيدونت ماوريس هندريريت."
                    }
                },
                {
                    "id": 1.2,
                    "title": "تحديد المصفوفات الخاصة",
                    "type": "reading",
                    "typeText": "مادة قرائية 1 ساعة",
                    "completed": true,
                    "content": [
                        {
                            "title": "فقرات عامة",
                            "text": [
                                "لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. نولا فاسيليسي. دونك فيل سابين ليغولا. فاسيليس أك بيبندوم لاكوس. موربي سيد فرينغيلا أورسي. نولام ديكتوم ليبرو فيتاي أوغي بيللينتيسك، نيك أككومسان دوي فيستيبولوم. إنتيجر سيت أميت دوي إن دوي ألايكويت ديكتوم.",
                                "لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. نولا فاسيليسي. دونك فيل سابين ليغولا. فاسيليس أك بيبندوم لاكوس. موربي سيد فرينغيلا أورسي. نولام ديكتوم ليبرو فيتاي أوغي بيللينتيسك، نيك أككومسان دوي فيستيبولوم. إنتيجر سيت أميت دوي إن دوي ألايكويت ديكتوم."
                            ]
                        },
                        {
                            "title": "وصف العمل",
                            "text": [
                                "لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. نولا فاسيليسي. دونك فيل سابين ليغولا. فاسيليس أك بيبندوم لاكوس. موربي سيد فرينغيلا أورسي. نولام ديكتوم ليبرو فيتاي أوغي بيللينتيسك، نيك أككومسان دوي فيستيبولوم. إنتيجر سيت أميت دوي إن دوي ألايكويت ديكتوم.",
                                "لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. نولا فاسيليسي. دونك فيل سابين ليغولا. فاسيليس أك بيبندوم لاكوس. موربي سيد فرينغيلا أورسي. نولام ديكتوم ليبرو فيتاي أوغي بيللينتيسك، نيك أككومسان دوي فيستيبولوم. إنتيجر سيت أميت دوي إن دوي ألايكويت ديكتوم."
                            ]
                        },
                        {
                            "title": "توثيق فني",
                            "text": [
                                "لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. نولا فاسيليسي. دونك فيل سابين ليغولا. فاسيليس أك بيبندوم لاكوس. موربي سيد فرينغيلا أورسي. نولام ديكتوم ليبرو فيتاي أوغي بيللينتيسك، نيك أككومسان دوي فيستيبولوم. إنتيجر سيت أميت دوي إن دوي ألايكويت ديكتوم.",
                                "لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. نولا فاسيليسي. دونك فيل سابين ليغولا. فاسيليس أك بيبندوم لاكوس. موربي سيد فرينغيلا أورسي. نولام ديكتوم ليبرو فيتاي أوغي بيللينتيسك، نيك أككومسان دوي فيستيبولوم. إنتيجر سيت أميت دوي إن دوي ألايكويت ديكتوم."
                            ]
                        }
                    ]
                },
                {
                    "id": 1.3,
                    "title": "الاختبار القضائي",
                    "type": "quiz",
                    "typeText": "اختبار قصير 1 ساعة",
                    "completed": true,
                    "content": {
                        title: 'اختبار الاسبوع الرابع',
                        date: '21 يوليو',
                        tries: ' 3 مرات كل 8 ساعات',
                        grade: 50,
                        quizQuestions: '#id',
                    }
                }
            ]
        },
        {
            "id": 2,
            "title": "المصفوفات الخاصة وترميز بعض عمليات المصفوفة",
            "subTitles": [
                {
                    "id": 2.1,
                    "title": "عنوان فرعي",
                    "type": "video",
                    "typeText": "فيديو 10 دقائق",
                    "completed": true,
                    "content": {
                        "link": "https://example.com/video.mp4",
                        "text": "لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. فيستيبولوم فيهيكولا أورنا سيت أميت ماغنا أوللامكوربر، أك تينسيدونت نيسل فيهيكولا. بروين لاوريت ديام إن إيرات فيفيرا، آت سوليكيتودين أوغي فيفيرا. سيد ألايكويت أورسي نون دوئي سكيليسكو، فيل تينسيدونت ماوريس هندريريت."
                    }
                },
                {
                    "id": 2.2,
                    "title": "تحديد المصفوفات الخاصة",
                    "type": "reading",
                    "typeText": "مادة قرائية 1 ساعة",
                    "completed": false,
                    "content": [
                        {
                            "title": "فقرات عامة",
                            "text": [
                                "لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. نولا فاسيليسي. دونك فيل سابين ليغولا. فاسيليس أك بيبندوم لاكوس. موربي سيد فرينغيلا أورسي. نولام ديكتوم ليبرو فيتاي أوغي بيللينتيسك، نيك أككومسان دوي فيستيبولوم. إنتيجر سيت أميت دوي إن دوي ألايكويت ديكتوم.",
                                "لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. نولا فاسيليسي. دونك فيل سابين ليغولا. فاسيليس أك بيبندوم لاكوس. موربي سيد فرينغيلا أورسي. نولام ديكتوم ليبرو فيتاي أوغي بيللينتيسك، نيك أككومسان دوي فيستيبولوم. إنتيجر سيت أميت دوي إن دوي ألايكويت ديكتوم."
                            ]
                        },
                        {
                            "title": "وصف العمل",
                            "text": [
                                "لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. نولا فاسيليسي. دونك فيل سابين ليغولا. فاسيليس أك بيبندوم لاكوس. موربي سيد فرينغيلا أورسي. نولام ديكتوم ليبرو فيتاي أوغي بيللينتيسك، نيك أككومسان دوي فيستيبولوم. إنتيجر سيت أميت دوي إن دوي ألايكويت ديكتوم.",
                                "لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. نولا فاسيليسي. دونك فيل سابين ليغولا. فاسيليس أك بيبندوم لاكوس. موربي سيد فرينغيلا أورسي. نولام ديكتوم ليبرو فيتاي أوغي بيللينتيسك، نيك أككومسان دوي فيستيبولوم. إنتيجر سيت أميت دوي إن دوي ألايكويت ديكتوم."
                            ]
                        },
                        {
                            "title": "توثيق فني",
                            "text": [
                                "لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. نولا فاسيليسي. دونك فيل سابين ليغولا. فاسيليس أك بيبندوم لاكوس. موربي سيد فرينغيلا أورسي. نولام ديكتوم ليبرو فيتاي أوغي بيللينتيسك، نيك أككومسان دوي فيستيبولوم. إنتيجر سيت أميت دوي إن دوي ألايكويت ديكتوم.",
                                "لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسيسينغ إيليت. نولا فاسيليسي. دونك فيل سابين ليغولا. فاسيليس أك بيبندوم لاكوس. موربي سيد فرينغيلا أورسي. نولام ديكتوم ليبرو فيتاي أوغي بيللينتيسك، نيك أككومسان دوي فيستيبولوم. إنتيجر سيت أميت دوي إن دوي ألايكويت ديكتوم."
                            ]
                        }
                    ]
                },
                {
                    "id": 2.3,
                    "title": "الاختبار القضائي",
                    "type": "quiz",
                    "typeText": "اختبار قصير 1 ساعة",
                    "completed": false,
                    "content": {
                        title: 'اختبار الاسبوع الرابع',
                        date: '21 يوليو',
                        tries: ' 3 مرات كل 8 ساعات',
                        grade: -1,
                        quizQuestions: '#id',
                    }

                },
                {
                    "id": 2.4,
                    "title": "كتابة مذكرة",
                    "type": "writing",
                    "typeText": "كتابة مذكرة",
                    "completed": false,

                }
            ]
        }
    ]
}