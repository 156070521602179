
import React from "react";

// fetch name from api 
export default function WelcomeSection(props) {
    const name = props.user?.name
    return (
        <section className="text-right text-darkBlue flex flex-col gap-5 my-2 mx-5"
            style={{ direction: 'rtl' }}>
            <h1 className=" text-4xl">
                مرحبا!
            </h1>
            <h2 className=" text-xl">شركة  بصيرتك ترحب بك , {name}</h2>
        </section>
    );

}