import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import Language from '../../componets/Language';
import Navigation from '../../componets/Navigation';
import Alert from '../../componets/Alert';
import { IoMdCheckmarkCircle } from "react-icons/io";
import { PiCaretDoubleRightFill } from "react-icons/pi";
import { GrCaretPrevious, GrCaretNext, GrFlag, GrStatusWarning } from "react-icons/gr";

export default function QuizPage({ content, id }) {
  const [state, setState] = useState({
    questions: dataTemp,
    currentIndex: 0,
    selectedOption: null,
    submited: false
  });
  const handleAnswerChange = (selectedOption) => {
    setState(prevState => {
      const updatedQuestions = [...prevState.questions];
      updatedQuestions[prevState.currentIndex].answer = selectedOption;
      return {
        ...prevState,
        questions: updatedQuestions
      };
    });
  };
  const handleNextQuestion = () => {
    setState(prevState => ({
      ...prevState,
      currentIndex: prevState.currentIndex < prevState.questions.length - 1 ? prevState.currentIndex + 1 : -1,
      selectedOption: ''
    }))
  };

  const handlePrevQuestion = () => {
    setState(prevState => ({
      ...prevState,
      currentIndex: prevState.currentIndex > 0 ? prevState.currentIndex - 1 : 0,
      selectedOption: ''
    }));
  }

  const handleMarkQuestion = () => {
    setState(prevState => ({
      ...prevState,
      questions: prevState.questions.map((question, index) =>
        index === prevState.currentIndex ? { ...question, mark: !question.mark } : question
      )
    }));
  }
  const handleSubmit = (qNum, selectedOption) => {
    setState(prevState => ({
      ...prevState,
      submited: true
    }));
    window.scrollTo({ top: 0, behavior: 'smooth' })
  };

  return (
    <div className='flex flex-col  text-right mb-5'>
      <Header index={state.currentIndex} submited={state.submited} setIndex={index => setState(prevState => ({ ...prevState, currentIndex: index }))} />
      <div className='max-w-7xl mx-auto p-3 flex flex-grow flex-col md:justify-around items-end'>
        {
          state.submited ? <Submited questions={state.questions} /> :
            state.currentIndex === -1 ? <Review questions={state.questions} onSubmit={handleSubmit} reviewQuestion={index => setState(prevState => ({ ...prevState, currentIndex: index }))} /> :
              <>
                <Navigation next={handleNextQuestion} prev={handlePrevQuestion} />
                <Question
                  question={state.questions[state.currentIndex]}
                  onAnswerChange={handleAnswerChange}
                  selectedOption={state.selectedOption}
                  setSelectedOption={selectedOption => setState(prevState => ({ ...prevState, selectedOption }))}
                />
                <div className='flex md:gap-14'>
                  <button
                    onClick={handleMarkQuestion}
                    className='flex flex-row justify-center items-center gap-2 md:gap-6 rounded-md h-28 w-36 md:w-52 bg-white active:bg-gray-100 text-primary border-primary border-2 mr-14 md:text-2xl'
                  >
                    <p>المراجعة</p>
                    <GrFlag />
                  </ button>
                  <button
                    onClick={handleNextQuestion}
                    className="rounded-md h-28 w-36 md:w-52 bg-darkBlue active:bg-primary text-white md:text-2xl"
                  >التالي</button>
                </div>
              </>
        }
      </div>
    </div>)
}
const Header = ({ index, setIndex, submited }) => {

  const navigate = useNavigate();

  if (submited) {
    return (
      <header className='border-b-4 border-lightGray'>
        <div className='p-3 md:p-5 max-w-7xl mx-auto my-4 flex justify-between items-center  text-darkBlue'>
          <div className='flex flex-col items-center my-4 md:text-2xl'>
            <p>
              تم تسليم الاختبار
            </p>
            <p className='text-base'>
              {new Date().toLocaleDateString('ar-EG', {
                day: 'numeric',
                month: 'short',
                hour: 'numeric',
                minute: 'numeric'
              })}
            </p >
          </div>
          <button
            onClick={() => setIndex(-1)}
            className='text-lg md:text-3xl text-darkBlue flex items-center gap-2 md:gap-5'>
            الرجوع
            <PiCaretDoubleRightFill />
          </button>
        </div>
      </header>
    )
  }
  else if (index >= -1) {
    return (
      <header className='border-b-4 border-lightGray'>
        <div className='p-3 md:p-5 max-w-7xl mx-auto flex flex-wrap-reverse justify-end items-center '>
          {
            (index !== -1) &&
            <button
              onClick={() => setIndex(-1)}
              className='text-darkBlue flex gap-5 md:text-2xl lg:text-4xl py-2'>
              <GrCaretPrevious className='text-darkBlue' />
              تصفح جميع الاسئلة
            </button>
          }
          <div className='flex-grow flex flex-col md:flex-row-reverse items-end md:justify-start md:items-center gap-4'>
            <button onClick={() => navigate(-1)}
              className='text-primary flex justify-end items-center text-lg md:text-2xl'>
              <p>رجوع</p>
              <GrCaretNext />
            </button>
            <div className="flex flex-col gap-3">
              <h2 className='text-2xl'>اختبار الاسبوع الاول</h2>
              <p className='text-sm'>اختبار مقيم بالدرجات . 30 دقيقة . 3 نقاط اجمالية</p>
              <div className="flex justify-between text-primary items-start ">
                <div className='flex md:inline gap-3'>
                  <p> تاريخ  التسليم</p>
                  <p>21 يوليو</p>
                </div>
                <Language />
              </div>
            </div>
          </ div>
        </div>
      </header >
    )
  }
};
const Question = ({
  readOnly = false,
  question,
  answer,
  setSelectedOption,
  onAnswerChange
}) => {
  const handleOptionChange = (event) => {
    const selected = Number(event.target.value);
    setSelectedOption(selected);
    onAnswerChange(selected);
  };

  const { qNum, questionText, choices, points } = question;

  return (
    <div className="mt-5 md:mt-10 pt-4 mb-4  text-base flex flex-col gap-5 md:gap-14">
      <div className="w-full flex justify-between items-center mb-2">
        <p className="bg-lightGray py-1 px-7 rounded-xl h-fit">
          نقطة {points}
          {readOnly && `/${answer ? 1 : 0}`}
        </p>
        <h3 className="text-3xl md:text-5xl">السؤال رقم {qNum}</h3>
      </div>
      <p className="md:text-3xl mb-5 md:mb-14">
        {questionText}
      </p>
      <div>
        {choices.map((choice, index) => (
          <div key={index} className="mb-2">
            <label htmlFor={`choice-${index}`} className="text-sm md:text-2xl">
              {choice}
            </label>
            <input
              id={`choice-${index}`}
              type="radio"
              value={index}
              checked={question.answer === index}
              onChange={readOnly ? null : handleOptionChange}
              disabled={readOnly}
              className="mx-2"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const Review = ({ questions, reviewQuestion, onSubmit }) => {

  const [message, setMessage] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleSubmit = () => {
    // Handle submission logic here
    if (isChecked && questions.every(q => q.answer !== null)) {
      onSubmit()
    } else {
      setMessage(true)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  };

  return (
    // <div className="text-right  hover:text-darkBlue flex flex-col items-end justify-end gap-10 my-14">
    <div className="mt-5 pt-4 mb-4  text-base flex flex-col gap-5 justify-between h-full" >
      {message &&
        <div
          className='flex justify-between items-start gap-6 bg-lightPink border-darkPink border-[5px] rounded-10 px-5 py-10'>
          <div className='flex-grow'>
            <p className='text-xl'>لم تتم الإجابة على جميع الأسئلة</p>
          </div>
          <GrStatusWarning color={"#B3261E"} size={24} />
        </div>
      }
      <h3 className="text-3xl md:text-5xl bg-white ">
        عرض جميع لاسئلة
      </h3>

      <div className=' border-[13px] border-darkGray 
      grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4
      '>
        {questions.map((ele, i) =>
          <div
            key={i}
            className='flex flex-col items-between justify-between text-right p-4 bg-veryLightGray h-40 flex-gow'>
            <div className='flex justify-between items-center text-2xl text-darkGray'>
              {ele.mark && <GrFlag size={30} />}
              <p className='flex-grow text-2xl'>{`السؤال رقم ${ele.qNum}`}</p>
            </div>
            <button
              onClick={() => reviewQuestion(ele.qNum - 1)}
              className='rounded-full bg-primary text-white w-full py-2 h-[px]'>
              مراجعة السؤال
            </button>
          </div>)}
      </div>

      <div className="flex items-start justify-end">
        <label htmlFor='submit' className="md:text-xl mx-2 text-darkGray hover:text-black" style={{ direction: 'rtl' }}>
          أعي أنا WIAM ABDULRAHMAN TALEB، أن إرسال أي عمل ليس عملي سيؤدي إلى الإخفاق النهائي في هذه الدورة التدريبية أو تعطيل حسابي في  تكنولاو.
        </label>
        <input
          id="submit"
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
          className="h-5 w-5 md:h-7 md:w-7 rounded-10"
        />
      </div>
      <div>
        <button
          disabled={!isChecked}
          onClick={handleSubmit}
          className={`text-primary md:text-2xl rounded-md h-28 w-36 md:w-52  border-primary border-2
                    ${isChecked ? 'bg-primary text-white hover:bg-darkBlue' : 'bg-lightGray text-secondary cursor-not-allowed border-none'}`}
        >
          إرسال
        </button>
      </div>
    </div>
  );
}
const Submited = ({ questions }) => {

  return (
    <div className="mt-5  pt-4 mb-4  text-base flex flex-col gap-5 justify-between h-full">
      <div className='bg-veryLightGreen border-lightGreen2 text-black flex justify-end sm:justify-between items-end flex-wrap-reverse gap-6 border-2 sm:border-[5px] rounded-10 p-2 sm:px-5 sm:py-10 ' >
        <Link
          to={'/welcomeTrainee/course/:id'}
          className='flex justify-center items-center
              w-44 h-20 bg-white text-veryDarkGreen border-lightGreen2 rounded-10 px-7 border-2 text-sm md:text-base'>
          <p>الانتقال إلى <br /> العنصر التالي</p>
        </Link>
        <div className="flex gap-5">
          <div className='flex-grow'>
            <p>تهانينا! لقد نجحت! </p>
            <p>
              تم إستلام الدرجة
              <span className='text-darkGreen'> 88% </span>
              لإجتياز الإختبار
              <span className='text-darkGreen'> 80% أو أعلى </span>
            </p>
          </div>
          <IoMdCheckmarkCircle color={"#485751"} size={36} />

        </div>
      </div>

      <div
        className='bg-lightPink border-darkPink text-black   flex justify-end sm:justify-between items-end flex-wrap-reverse gap-6 border-2 sm:border-[5px] rounded-10 p-2 sm:px-5 sm:py-10 ' >
        <Link
          to={'/welcomeTrainee/course/:id'}
          className='flex justify-center items-center w-44 h-20 bg-white text-darkRed border-darkRed   rounded-10  border-2 text-sm md:text-base'>
          <p>المحاولة مرة اخرى</p>
        </Link>
        <div className="flex gap-5">
          <div className='flex-grow'>
            <p>أعد المحاولة بمجرد أن تكون جاهزا ! </p>
            <p>
              تم إستلام الدرجة
              <span className='text-darkRed'> 88% </span>
              لإجتياز الإختبار
              <span className='text-darkRed'> 80% أو أعلى </span>
            </p>
          </div>
          <GrStatusWarning color={"#B3261E"} size={36} />

        </div>
      </div>

      <div>
        <Question
          readOnly={true}
          answer={true}
          question={questions[0]} />
        <Alert answer={answersTemp[0].answer} explain={answersTemp[0].explain} />
        <Question
          readOnly={true}
          answer={false}
          question={questions[1]} />
        <Alert answer={answersTemp[1].answer} explain={answersTemp[1].explain} />
      </div>
    </div >

  )
}

// ! temp variables
const dataTemp = [
  {
    "qNum": 1,
    "questionText": 'أي من هذه المصطلحات يصف بشكل أفضل نوع الذكاء الاصطناعي المستخدم في فلاتر البريد الإلكتروني العشوائي والتعرف على الكلام والتطبيقات المحددة الأخرى اليوم؟',
    "choices": ['الذكاء الاصطناعي العام', 'الذكاء الاصطناعي العام', 'الذكاء الاصطناعي العام'],
    "answer": null,
    "mark": false,
    "points": 1
  },
  {
    "qNum": 2,
    "questionText": 'أي من هذه المصطلحات يصف بشكل أفضل نوع الذكاء الاصطناعي المستخدم في فلاتر البريد الإلكتروني العشوائي والتعرف على الكلام والتطبيقات المحددة الأخرى اليوم؟',
    "choices": ['الذكاء الاصطناعي العام', 'الذكاء الاصطناعي العام', 'الذكاء الاصطناعي العام'],
    "answer": null,
    "mark": false,
    "points": 1
  },
  {
    "qNum": 3,
    "questionText": 'أي من هذه المصطلحات يصف بشكل أفضل نوع الذكاء الاصطناعي المستخدم في فلاتر البريد الإلكتروني العشوائي والتعرف على الكلام والتطبيقات المحددة الأخرى اليوم؟',
    "choices": ['الذكاء الاصطناعي العام', 'الذكاء الاصطناعي العام', 'الذكاء الاصطناعي العام'],
    "answer": null,
    "mark": false,
    "points": 1
  },
  {
    "qNum": 4,
    "questionText": 'أي من هذه المصطلحات يصف بشكل أفضل نوع الذكاء الاصطناعي المستخدم في فلاتر البريد الإلكتروني العشوائي والتعرف على الكلام والتطبيقات المحددة الأخرى اليوم؟',
    "choices": ['الذكاء الاصطناعي العام', 'الذكاء الاصطناعي العام', 'الذكاء الاصطناعي العام'],
    "answer": null,
    "mark": false,
    "points": 1
  },
  {
    "qNum": 5,
    "questionText": 'أي من هذه المصطلحات يصف بشكل أفضل نوع الذكاء الاصطناعي المستخدم في فلاتر البريد الإلكتروني العشوائي والتعرف على الكلام والتطبيقات المحددة الأخرى اليوم؟',
    "choices": ['الذكاء', 'الذكاء الاصطناعي', 'الذكاء العام'],
    "answer": null,
    "mark": false,
    "points": 1
  }
]

const answersTemp = [{
  qNum: 1,
  answer: true,
  explain: "التصميم الذي يركز على المستخدم هو إطار يضع المستخدم في المقدمة والوسط. من خلال التركيز على المستخدم، يجب على المصممين النظر في القصة والعواطف والأفكار التي تم جمعها عنهم."
},
{
  qNum: 2,
  answer: false,
  explain: "التصميم الذي يركز على المستخدم هو إطار يضع المستخدم في المقدمة والوسط. من خلال التركيز على المستخدم، يجب على المصممين النظر في القصة والعواطف والأفكار التي تم جمعها عنهم."
},
]
