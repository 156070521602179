import React, { useEffect, useState } from 'react'
import NavBar from '../../componets/NavBar'
import Footer from '../../componets/Footer'
import { useAuth } from '../../contexts/authContext';
import { user_submissions } from '../../services/api';
import { useNavigate } from 'react-router-dom';

export default function Submissions() {
  const [isLoading, setIsLoading] = useState(true)
  const [showEx, setEx] = useState(true)
  const [respond, setRespond] = useState([]);

  const { user } = useAuth()
  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await user_submissions(user.user_id);
        setRespond(data);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setIsLoading(false);
      }
    }
    loadData();
  }, [user?.user_id]);

  const handleSelect = (item) => {
    navigate(`/trainee/submissions/${item.submission_id}`, { state: { item } });
  };

  return (
    <div className=' max-w-7xl min-h-screen mx-auto flex flex-col justify-between py-3'>
      <NavBar theme="light" />
      <div className='flex-grow w-full max-w-[950px] mx-auto text-right pt-5 flex flex-col gap-2 md:gap-6'>
        <h2 className='text-darkBlue text-4xl mx-5 my-2'>التقيمات</h2>
        <div className="bg-darkBlue w-full flex flex-col gap-2 py-5 md:py-10 px-10 rounded-10 text-white">
          {isLoading ?
            <div className="animate-pulse gap-4 flex items-end flex-col bg-white rounded-10 p-3">
              <div className="h-4 bg-gray-300 rounded w-3/4"></div>
              <div className="h-4 bg-gray-300 rounded w-5/6"></div>
              <div className="h-4 bg-gray-300 rounded w-2/3"></div>
            </div> :

            <>{
              !showEx ?
                <div className="space-y-4 flex items-end flex-col bg-white rounded-10 p-3 h-24">
                  <p className='font-bold text-darkBlue my-auto text-2xl'>لا يوجد جلسة مرسلة</p>
                </div> :

                respond.map((item, i) =>
                  <div key={i} className='bg-white w-full rounded-10 p-3 text-darkBlue md:text-xl flex flex-col gap-2' >
                    <p className='font-bold'> ترافعت بقضية <span className='font-semibold'>{item.submission_type}</span></p>
                    <p className='font-bold'> الدور <span className='font-semibold'>{item.trainee_role}</span></p>
                    <p className='font-bold'> الحالة <span className='font-semibold'>{item.submission_status == 'new' ? 'لم يتم تقييمه' : 'تم تقييمه '}</span> </p>
                    <button
                      onClick={() => handleSelect(item)}
                      disabled={item.submission_status == 'new'}
                      className={`bg-darkBlue text-white rounded-10 w-fit px-4 py-3 ${item.submission_status == 'new' && 'bg-darkBlue/60'}`}>
                      {item.submission_status == 'new' ? 'لم يتم تقييمه' : 'عرض التقيم'}
                    </button>
                  </div>
                )}</>}
          {showEx && <div className='bg-white w-full rounded-10 p-3 text-darkBlue md:text-xl flex flex-col gap-2' >
            <p className='font-bold'> ترافعت بقضية <span className='font-semibold'>جنائية</span></p>
            <p className='font-bold'> الدور <span className='font-semibold'>مدعي</span></p>
            <p className='font-bold'> الحالة <span className='font-semibold'>لم يتم تقييمه</span> </p>
            <button
              disabled={true}
              className={`bg-darkBlue text-white rounded-10 w-fit px-4 py-3 bg-darkBlue/60`}>
              لم يتم تقييمه'
            </button>
          </div>}

        </div>
        <button onClick={() => setEx(!showEx)} className="bg-black text-white p-3 m-4 w-fit">show/hide example</button>
      </div>
      <Footer theme='light' />
    </div>
  )
}
