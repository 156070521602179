import React from 'react'
import { IoIosCheckmarkCircleOutline, IoMdCloseCircleOutline } from 'react-icons/io'

export default function Alert({ answer, explain }) {
    return (
        <div
            className={`max-w-[1094px] ml-auto text-right flex justify-between items-start gap-5 ${answer ? 'bg-veryLightGreen' : 'bg-lightPink'} p-2 sm:px-5 sm:py-10`}>
            <div className={`text-xl md:text-2xl flex-grow ${answer ? "text-veryDarkGreen" : "text-darkRed"}`}>
                <p>{answer ? 'صحيح' : ' غير صحيح'}</p>
                <p className='text-xs md:text-xl'>{explain}</p>
            </div>
            {answer ?
                <IoIosCheckmarkCircleOutline color={"#485751"} size={36} /> :   
                <IoMdCloseCircleOutline color={"#B3261E"} size={36} />}
        </div>
    )
}