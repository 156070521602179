import React from "react";
import NavBar from "../componets/NavBar";
import CourseItem from "../componets/CourseItem";
import { IoMdTime } from "react-icons/io";

const Courses = () => {
  return (
    <div className="max-w-7xl mx-auto flex flex-col mb-[100px]">
      <NavBar theme="light" />

      <h1 className="text-right lg:text-2xl text-lg font-bold text-primary mx-10 my-10">
        المحتويات التعليمية
      </h1>

      <div className="w-full px-2 flex gap-2 items-center justify-center py-5">
        <p className="text-primary md:text-2xl">
          سيتم ادراج دروس اضافية مستقبلا , ترقبونا
        </p>
        <IoMdTime className="text-primary size-10 md:size-14" />
      </div>
      {/* <CourseItem/> */}
    </div>
  );
};

export default Courses;
