import React, { useState } from 'react';
import { MdLanguage, MdKeyboardArrowDown } from "react-icons/md";

export default function Language() {
    const [language, setLanguage] = useState('العربية');
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const changeLanguage = (lang) => {
        setLanguage(lang);
        setDropdownOpen(false);
    };

    return (
        <div className='relative flex items-center text-primary '>
            <div
                className={`flex items-center cursor-pointer ${language === 'العربية' ? 'flex-row-reverse' : 'flex-row'}`}
                onClick={toggleDropdown}
            >
                <MdLanguage size={24} />
                <p className='mx-2 w-14'>{language}</p>
                <MdKeyboardArrowDown size={24} />
            </div>

            {dropdownOpen && (
                <div className="absolute top-full mt-2 bg-white shadow-md rounded-md w-32">
                    <ul>
                        <li
                            className={`p-2 cursor-pointer ${language === 'English' ? 'bg-gray-200 cursor-default' : 'hover:bg-gray-100'}`}
                            onClick={() => changeLanguage('English')}
                        >
                            English
                        </li>
                        <li
                            className={`p-2 cursor-pointer ${language === 'العربية' ? 'bg-gray-200 cursor-default' : 'hover:bg-gray-100'}`}
                            onClick={() => changeLanguage('العربية')}
                        >
                            العربية
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
}
