import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useAuth } from "../contexts/authContext";
import Swal from 'sweetalert2';
import { upload_submission } from '../services/api';

export default function VoiceUpload() {

    const { user } = useAuth();

    const [formData, setFormData] = useState({
        caseType: '',
        secondPerson: '',
        file: null,
        role: '',
        sessionToken: ''
    });
    const [audioURL, setAudioURL] = useState('');
    const [isPreviewing, setIsPreviewing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleFileChange = (e) => {

        const selectedFile = e.target.files[0];

        if (selectedFile) {
            // MAX size check 
            // const MAX_FILE_SIZE = 5 * 1024 * 1024; // temp
            // if (selectedFile.size > MAX_FILE_SIZE)
            //     return setErrorMessage("حجم الملف يجب أن يكون أقل من _ ميجابايت.");

            const url = URL.createObjectURL(selectedFile);
            setAudioURL(url);
            setIsPreviewing(true);
            setFormData({ ...formData, file: selectedFile });
            setErrorMessage('');
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };
    function isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    const handleSubmit = async () => {
        const { secondPerson, file, caseType, role, sessionToken } = formData;

        if (!file || !caseType || !role || !sessionToken)
            return setErrorMessage("جميع الحقول مطلوبة");

        if (!isValidEmail(secondPerson) && secondPerson)
            return setErrorMessage("البريد الإلكتروني غير صالح");

        const caseData = {
            case_type: caseType,
            trainee1_id: user?.user_id,
            trainee1_name: user?.name,
            trainee1_role: role,
            case_code: sessionToken
        };

        // second user if it exist
        if (secondPerson) {
            caseData.trainee2_email = secondPerson;
            caseData.trainee2_role = role === "مدعى" ? "مدعى عليه" : "مدعى";
        }

        const requestBody = new FormData();
        requestBody.append('case', JSON.stringify(caseData));
        requestBody.append('file', file);

        setErrorMessage('');
        setIsLoading(true);

        try {
            const response = await upload_submission(requestBody);
            Swal.fire({
                text: "تم رفع الجلسة بنجاح",
                icon: "success"
            }).then(() => navigate("/trainee"));
        } catch (err) {
            if (err.response?.status === 404) {
                const errorMessage = err.response.data.detail;
                if (errorMessage?.includes('code has already been used or does not exist')) {
                    setErrorMessage('كود الجلسة مستخدم بالفعل أو غير موجود. يرجى التحقق.');
                } else {
                    setErrorMessage('حدث خطأ أثناء إرسال البيانات. يرجى المحاولة مرة أخرى.');
                }
            } else {
                setErrorMessage('حدث خطأ أثناء إرسال البيانات');
            }
        } finally {
            setIsLoading(false);
        }

    }

    return (
        <div className="">

            <h3 className="text-primary text-right  md:text-2xl lg:text-4xl my-10">
                رفع الجلسة
            </h3>
            <div
                className="text-right rounded-10 p-2 md:p-5 py-7 md:py-10 bg-darkBlue h-full grid gap-5"
                style={{ direction: "rtl" }}
            >

                <div
                    className="w-full rounded-10 text-darkBlue  bg-white text-center p-4 grid grid-cols-[auto_70%] flex-col gap-y-3 sm:gap-x-1 md:gap-5 items-center justify-center">
                    {errorMessage && (
                        <p className="text-red-500 text-lg col-span-2">{errorMessage}</p>
                    )}
                    <label className="text-xs md:text-lg text-right">ترافعت بقضية</label>
                    <select
                        name="caseType"
                        value={formData.caseType}
                        onChange={handleInputChange}
                        className="bg-[#F5F5F7] rounded-10 p-2 w-full text-xs h-9"

                    >
                        <option value="" disabled>اختر نوع القضية</option>
                        <option value="قضية تجارية">قضية تجارية</option>
                        <option value="قضية جنائية">قضية جنائية</option>
                        <option value="قضية احوال شخصية">قضية احوال شخصية</option>
                    </select>
                    <label className="text-xs md:text-lg text-right">
                        ترافعت مع
                    </label>
                    <input
                        type="text"
                        name="secondPerson"
                        value={formData.secondPerson}
                        onChange={handleInputChange}
                        placeholder=" أدخل بريد الشخص الثاني"
                        className="bg-[#F5F5F7] rounded-10 p-2 h-9 text-xs"
                    />
                    <label className="text-xs md:text-lg text-right">قمت بدور</label>
                    <select
                        name="role"
                        value={formData.role}
                        onChange={handleInputChange}
                        className="bg-[#F5F5F7] rounded-10 p-2 h-9 text-xs"
                    >
                        <option value="" disabled>اختر الدور</option>
                        <option value="مدعى">مدعى</option>
                        <option value="مدعى عليه">مدعى عليه</option>
                    </select>
                    <label className="text-xs md:text-lg text-right">
                        رفع ملف الجلسة
                    </label>
                    <label className="bg-primary rounded-10 h-9 p-2 w-full text-xs text-white text-center cursor-pointer whitespace-nowrap text-ellipsis overflow-hidden">
                        {formData.file ? formData.file.name : "اختيار الملف"}
                        <input
                            type="file"
                            accept="audio/*"
                            onChange={handleFileChange}
                            className="hidden"

                        />
                    </label>
                    {isPreviewing && (
                        <div className="w-full col-span-2">
                            <audio controls src={audioURL} className="w-full" />
                        </div>
                    )}

                    <label className="text-xs md:text-lg text-right">كود الجلسة</label>
                    <input
                        type="text"
                        name="sessionToken"
                        value={formData.sessionToken}
                        onChange={handleInputChange}
                        placeholder="أدخل كود الجلسة "
                        className="bg-[#F5F5F7] rounded-10 p-2  text-xs w-full h-9"
                    />
                </div>
                <button
                    onClick={handleSubmit}
                    disabled={isLoading}
                    className={`bg-white py-2 text-center mt-10 mb-5 mx-auto text-primary rounded-full w-full  hover:bg-white/95 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                    {isLoading ? 'جاري التحميل...' : 'رفع الجلسة'}
                </button>

            </div>

        </div>
    );
}
