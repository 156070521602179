import React from "react";
import logo from "../assets/images/largeLogo.png";
const Hero = () => {
  return (
    <div className="flex justify-center ">
      <div className="flex flex-col text-center gap-0">
        <img src={logo} alt="Baseratk" className="w-fit self-center object-fill" />
        <h2 className="lg:text-[30px] text-[#fff] text-[15px]"> <span className="text-[#dea778]"> طالب قانون وتدور فرصة للتدريب المهني ؟  </span> وصلت لمكانك</h2>
      </div>
    </div>
  );
};

export default Hero;
