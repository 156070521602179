import React from "react";
import { FaXTwitter } from "react-icons/fa6";
import { FaTiktok } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";

const Footer = ({theme}) => {

  const BgColor = theme === 'light' ? 'bg-black' : 'bg-white'
  const iconColor = theme === 'light' ? 'white' : 'black'

  return (
    <div className="flex gap-2 mt-[100px] ml-3">
      <a href="https://x.com/baseratk_sa" className={`${BgColor} rounded-full h-[31px] w-[31px] p-2 hover:bg-[#DCC5FE] cursor-pointer`}>
        <FaXTwitter color={iconColor} /> {/* Applied iconColor */}
      </a>
      <a href="https://www.tiktok.com/@baseratk" className={`${BgColor} rounded-full h-[31px] w-[31px] p-2 hover:bg-[#DCC5FE] cursor-pointer`}>
        <FaTiktok color={iconColor} /> {/* Applied iconColor */}
      </a>
      <a href="https://www.linkedin.com/in/baseratk-sa-689709326" className={`${BgColor} rounded-full h-[31px] w-[31px] p-2 hover:bg-[#DCC5FE] cursor-pointer`}>
        <FaLinkedinIn color={iconColor} /> {/* Applied iconColor */}
      </a>
      <a href="mailto:Baseratk.sa@gmail.com" className={`${BgColor} rounded-full h-[31px] w-[31px] p-2 hover:bg-[#DCC5FE] cursor-pointer`}>
        <MdAlternateEmail color={iconColor} /> {/* Applied iconColor */}
      </a>
    </div>
  );
};

export default Footer;
